import { mdiClose, mdiDownloadOutline, mdiFileOutline, mdiUploadCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { useLoader } from "../../../components/context/LoaderContext";

function ImportLeadsDialog({ onClose, open, handleReloadFunc }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState("");

    const handleDownSampleFile = () => {
        const fileUrl = `${process.env.REACT_APP_API_URL}storage/Sample-LeadData.xlsx`;
        const fileName = 'Sample-LeadData.xlsx';
        downloadFile(fileUrl, fileName);
    }

    function downloadFile(url, fileName) {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const validationSchema = yup.object().shape({
        import_file: yup
            .mixed()
            .required('Please select a file')
    });

    const handleFileChanges = (e) => {
        const file = e.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2 MB
        const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                setFileError("Invalid file type. Please upload an Excel file (.xls, .xlsx).");
                setSelectedFile(null);
                return;
            }
            if (file.size > maxSize) {
                setFileError("File size exceeds 2 MB. Please upload a smaller file.");
                setSelectedFile(null);
                return;
            }
            setSelectedFile(file);
            setFileError("");
        }
    }

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setFileError("");
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const formData = new FormData();
        formData.append('lead_file', values.import_file)

        if (!fileError) {
            showLoader()
            await axiosInstance.post("api/lead/import", formData).then(response => {
                if (response.status === 200) {
                    hideLoader()
                    showSnackbar(response.data.message, 'success')
                    onClose()
                    handleReloadFunc()
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        const errors = error.response.data.errors;
                        Object.keys(errors).forEach(key => {
                            onSubmitProps.setFieldError(key, errors[key][0]);
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        }
    }

    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Import Leads</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        import_file: '',
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            setFieldValue,
                            handleBlur,
                            handleSubmit,
                            errors,
                            touched,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="psuedo-upload-block">
                                    <div className="content">
                                        {!selectedFile ? (
                                            <div className="dnd-block">
                                                <Icon path={mdiUploadCircleOutline} className="icon" color={'currentColor'} size={2} />
                                                <Typography variant="body-large-semibold" component={'p'}>Drag & drop file here or click here to upload</Typography>
                                                <ul className="details">
                                                    <li>Allowed file: xls, xlsx</li>
                                                    <li>Maximum file size: 2 MB</li>
                                                </ul>
                                                <Button color="secondary" size="small">Browse File</Button>
                                            </div>
                                        ) : (
                                            <div className="dnd-block view">
                                                <Icon path={mdiFileOutline} className="icon" color={'currentColor'} size={2} />
                                                <Typography variant="body-large-semibold" component={'p'}>{selectedFile.name}</Typography>
                                                <span className="details">Size: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
                                                <Button color="secondary" size="small" onClick={handleRemoveFile}>Remove File</Button>
                                            </div>
                                        )}

                                        {fileError && (
                                            <Typography variant="body2" color="error">
                                                {fileError}
                                            </Typography>
                                        )}
                                    </div>
                                    {!selectedFile && (
                                        <input
                                            type="file"
                                            id="import_file"
                                            name="import_file"
                                            className="psuedo-upload-element"
                                            onChange={(e) => {
                                                setFieldValue('import_file', e.currentTarget.files[0]);
                                                handleFileChanges(e);
                                            }}
                                        //    onBlur={handleBlur}
                                        // error={errors.import_file && touched.import_file}
                                        // helperText={(errors.import_file && touched.import_file) && errors.import_file}
                                        />
                                    )}
                                    {(errors.import_file && !selectedFile) && <span className="easy-edit-validation-error">{errors.import_file}</span>}
                                </div>

                                <Grid container>
                                    <Grid item xs="auto">
                                        <Button variant="text" className="ml-n16" onClick={() => handleDownSampleFile()}>
                                            <span>
                                                <Icon path={mdiDownloadOutline} color="currentColor" size={1} />
                                                <span>Download Sample File</span>
                                            </span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                            {/* <Button type="submit" disabled={!selectedFile}>Import</Button> */}
                                            <Button type="submit">Import</Button>
                                            <Button color="secondary" onClick={onClose}>Cancel</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default ImportLeadsDialog;
