import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Menu, MenuItem, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material";
import AvatarInitials from "../../components/common/AvatarInitials";
import Icon from "@mdi/react";
import { mdiBriefcaseCheckOutline, mdiCalendarWeekBeginOutline, mdiContentCopy, mdiDomain, mdiDotsVertical, mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import ConvertLeadToDealDialog from "./components/ConvertLeadToDealDialog";
import EasyEdit, { Types } from "react-easy-edit";
import FormGroupStatic from '../../components/common/FormGroupStatic';
import EasyEditCustomTextbox from '../../components/common/EasyEditCustomTextbox';
import EasyEditCustomSelect from '../../components/common/EasyEditCustomSelect';
import EasyEditCustomMultiSelect from '../../components/common/EasyEditCustomMultiSelect';
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { capitalize, DeleteDialog, DeleteDialogConfig, validateField } from "../../utils/Utils";
import NoteList from "../../components/common/NoteList";
import ActivityList from "../../components/common/ActivityList";

function LeadDetails(props) {
    const { id } = useParams();
    const leadId = id
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [reloadPage, setReloadPage] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const userPermissions = useSelector((state) => state.user.permission);
    const [leadData, setLeadData] = useState();
    const [leadStages, setLeadStages] = useState();
    const [displayleadStages, setDisplayleadStages] = useState();
    const [defaultStageId, setDefaultStageId] = useState();
    const [converLeadState, setConverLeadState] = useState({});
    const [salutationList, setSalutationList] = useState({});
    const [leadSourceArray, setLeadSourceArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [productList, setProductList] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState([]);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            getData();
        }
        fetchData();
    }, [reloadPage]);


    const getData = async () => {
        await axiosInstance.get('api/lead/edit/' + leadId).then((response) => {
            if (response.status === 200) {
                setLeadData(response?.data?.data?.record);
                setLeadStages(response?.data?.data?.leadStage)
                setSalutationList(response?.data?.data?.salutationArray)
                setLeadSourceArray(response?.data?.data?.LeadSource)
                setCountryArray(response?.data?.data?.countries)
                setSelectedData(response?.data?.data?.selectedData)
                setProductList(response?.data?.data?.products)
                setSelectedProductIds(response?.data?.data?.selected_product)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/leads");
                    }
                }
            });
    }

    //========Start::Write a code for handle change stage at top bar==========//
    useEffect(() => {
        const selectedLeadStage = leadStages && leadStages.find(stage => stage.id === leadData?.stage_id);
        if (selectedLeadStage) {
            setDisplayleadStages(selectedLeadStage.name);
        }
    }, [leadData?.stage_id, leadStages]);


    const handleLeadStageChange = (e) => {
        setDefaultStageId(e.target.value)
    }

    const [editCurrentStatusAnchorEl, setEditCurrentStatusAnchorEl] = React.useState(null);
    const handleEditCurrentStatusClick = (event) => {
        setEditCurrentStatusAnchorEl(event.currentTarget);
    };
    const handleEditCurrentStatusClose = () => {
        setDefaultStageId(defaultStageId)
        setEditCurrentStatusAnchorEl(null);
    };
    const editCurrentStatusOpen = Boolean(editCurrentStatusAnchorEl);

    const handleLeadStageSubmit = async () => {
        const data = {
            stage_id: defaultStageId,
            id: leadId,
            first_name: leadData.first_name,
            last_name: leadData.last_name,
            product_id: [],
            organization_name: leadData.organization_name,
        }

        await axiosInstance.post("/api/lead/update", data).then(response => {
            if (response.status === 200) {
                setReloadPage(reloadPage ? false : true)
                showSnackbar(response.data.message, "success");
                setEditCurrentStatusAnchorEl(null);
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                    navigate("/leads");
                }
            }
        });

    }
    //========End::Write a code for handle change stage at top bar==========//


    const handleReloadFunc = () => {
        setReloadPage(reloadPage ? false : true);
    }


    //=======Start::Write a code for Conver Lead to Deal=======//
    const [convertLeadToDealDialogOpen, setConvertLeadToDealDialogOpen] = React.useState(false);
    const openConvertLeadToDealDialog = () => {
        var LeadTitle = leadData?.salutation ? leadData.salutation + ". " : ""
        LeadTitle = LeadTitle + leadData?.first_name + " " + leadData?.last_name
        setConverLeadState({ 'id': id, 'name': LeadTitle, 'organization': leadData.organization_name, 'title': leadData.title })
        setConvertLeadToDealDialogOpen(true);
    }
    const closeConvertLeadToDealDialog = () => {
        setConvertLeadToDealDialogOpen(false);
    }
    //=======End::Write a code for Conver Lead to Deal=======//


    //=======Start::Write a code for delete lead=======//
    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/lead/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/leads");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete lead=======//

    //======Start::Write a code for default data for editInline==========//
    const [allowEditInline, setAllowEditInline] = React.useState(true);

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const handleSaveData = async (value, name, type = null) => {
        const formData = new FormData();
        formData.append('id', leadId)
        formData.append('first_name', leadData?.first_name)
        formData.append('last_name', leadData?.last_name)
        formData.append('organization_name', leadData?.organization_name)
        if (type !== 'select') {
            for (var k = 0; k < selectedProductIds.length; k++) {
                formData.append('product_id[]', selectedProductIds[k])
            }
        }
        formData.append('lead_source', leadData?.lead_source)
        formData.append('country_id', leadData?.country_id || '')
        formData.append('salutation', leadData?.salutation)

        if (type === 'file') {
            for (var i = 0; i < value.length; i++) {
                formData.append(name + '[]', value[i])
            }
        } else if (type === 'select') {
            for (var j = 0; j < value.length; j++) {
                formData.append(name + '[]', value[j])
            }
        } else {
            formData.append([name], value === 'N/A' ? '' : value)
        }

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(leadData, name);
        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/lead/update", formData).then(response => {
                if (response.status === 200) {
                    setReloadPage(reloadPage ? false : true)
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(allowEditInline ? false : true)
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        } else {
            setAllowEditInline(true)
        }
    }
    //======End::Write a code for default data for editInline==========//

    const [leadContextMenuAnchorEl, setLeadContextMenuAnchorEl] = React.useState(null);
    const leadContextMenuOpen = Boolean(leadContextMenuAnchorEl);
    const handleLeadContextMenuClick = (event) => {
        setLeadContextMenuAnchorEl(event.currentTarget);
    };
    const handleLeadContextMenuClose = () => {
        setLeadContextMenuAnchorEl(null);
    };

    return (
        <>
            <Helmet>
                <title>Lead Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            {leadData &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="card p-16">
                            <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                                <Grid item xs={'auto'} className="flex-shrink-1">
                                    <Grid container alignItems={'flex-start'} spacing={1.5}>
                                        <Grid item xs="auto">
                                            <AvatarInitials name={leadData?.first_name + " " + leadData?.last_name} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant='title-medium-bold' component={'h6'}>
                                                {leadData?.salutation ? leadData.salutation + ". " : ""}{" "}
                                                {leadData?.first_name || 'N/A'} {leadData?.last_name || 'N/A'}
                                            </Typography>
                                            <ul className='inline-details'>
                                                <li><Tooltip title="Title"><span>{leadData?.title || 'N/A'}</span></Tooltip></li>
                                                <li>
                                                    <Tooltip title="Created Date">
                                                        <span>
                                                            <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                            {leadData && leadData.created_at ? moment.utc(leadData.created_at).tz(preTimeZoneKey).format(preferredDateFormat) : 'N/A'}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip title="Company Name">
                                                        <span>
                                                            <Icon path={mdiDomain} color="currentColor" size={1} />{leadData?.organization_name || 'N/A'}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <div className="edit-lead-deal-status">
                                                        <span className="status-badge warning">{displayleadStages}</span>
                                                        <Tooltip title="Change Current Lead Status">
                                                            <button type="button" onClick={handleEditCurrentStatusClick}>
                                                                <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                            </button>
                                                        </Tooltip>

                                                        <Popover
                                                            open={editCurrentStatusOpen}
                                                            anchorEl={editCurrentStatusAnchorEl}
                                                            onClose={handleEditCurrentStatusClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center'
                                                            }}
                                                            classes={{
                                                                paper: 'utility-menu'
                                                            }}
                                                        >
                                                            <Typography variant="body-large-bold" component={'p'} className="mb-16">Change Lead Status</Typography>
                                                            <TextField
                                                                select
                                                                label="Lead Stage"
                                                                //defaultValue={displayleadStages}
                                                                value={defaultStageId}
                                                                name="stage_id"
                                                                variant="filled"
                                                                onChange={(e) => handleLeadStageChange(e)}
                                                            >
                                                                {
                                                                    leadStages && leadStages.length > 0 && leadStages.map((lstage, index) => (
                                                                        <MenuItem key={index} value={lstage.id}>{lstage.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6}>
                                                                    <Button size="small" className="btn-block" onClick={() => handleLeadStageSubmit()}>Save</Button>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Button size="small" color="secondary" className="btn-block" onClick={handleEditCurrentStatusClose}>Cancel</Button>
                                                                </Grid>

                                                            </Grid>
                                                        </Popover>

                                                    </div>
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                    <Stack direction={"row"} spacing={1} sx={{ marginRight: 0.5 }}>
                                        {userPermissions && userPermissions.includes("lead_create") &&
                                            <Button size={"small"} onClick={openConvertLeadToDealDialog}>
                                                <span>
                                                    <Icon path={mdiBriefcaseCheckOutline} color={'currentColor'} size={1} />
                                                    <span>Convert to Deal</span>
                                                </span>
                                            </Button>
                                        }
                                        <Tooltip title="More">
                                            <Button color={'secondary'} className="icon-only" size={'small'} onClick={handleLeadContextMenuClick}>
                                                <Icon path={mdiDotsVertical} color={'currentColor'} size={1} />
                                            </Button>
                                        </Tooltip>
                                        <Menu
                                            anchorEl={leadContextMenuAnchorEl}
                                            open={leadContextMenuOpen}
                                            onClose={handleLeadContextMenuClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                            {userPermissions && userPermissions.includes("lead_create") &&
                                                <MenuItem onClick={handleLeadContextMenuClose}>
                                                    <Icon path={mdiContentCopy} color={'currentColor'} size={1} />
                                                    <span>Clone Lead</span>
                                                </MenuItem>
                                            }
                                            {userPermissions && userPermissions.includes("lead_delete") &&
                                                <MenuItem onClick={(e) => {
                                                    deleteData(leadId); handleLeadContextMenuClose();
                                                }}>
                                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                    <span>Delete</span>
                                                </MenuItem>
                                            }
                                        </Menu>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xxl={8} xs={12}>
                        <div className="card p-16 height-100p">
                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-12">Personal Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'salutation') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={leadData?.salutation || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Salutation" fieldValue={leadData?.salutation || ''}
                                                items={
                                                    Object.entries(salutationList).map(([key, value]) => (
                                                        { value: key, option: value }
                                                    ))
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Salutation" value={leadData?.salutation || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['salutation'] && <span className="easy-edit-validation-error">{backendErrorMessages['salutation']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'first_name') }}
                                        onCancel={() => { onCancelEdit('first_name') }}
                                        value={leadData?.first_name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="First Name" value={leadData?.first_name} errorMessages={errorMessages['first_name']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="First Name" value={leadData?.first_name || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'first_name', 'First Name')}
                                        validationMessage={errorMessages['first_name']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['first_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['first_name']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'last_name') }}
                                        onCancel={() => { onCancelEdit('last_name') }}
                                        value={leadData?.last_name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Last Name" value={leadData?.last_name} errorMessages={errorMessages['last_name']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Last Name" value={leadData?.last_name || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'last_name', 'Last Name')}
                                        validationMessage={errorMessages['last_name']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['last_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['last_name']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'title') }}
                                        onCancel={() => { onCancelEdit('title') }}
                                        value={leadData?.title || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Title" value={leadData?.title} errorMessages={errorMessages['title']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Title" value={leadData?.title || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'title', 'Title', '', false)}
                                        validationMessage={errorMessages['title']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['title'] && <span className="easy-edit-validation-error">{backendErrorMessages['title']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'primary_email') }}
                                        onCancel={() => { onCancelEdit('primary_email') }}
                                        value={leadData?.primary_email || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Email" value={leadData?.primary_email} errorMessages={errorMessages['primary_email']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Email" element={
                                            <a href={"mailto:" + leadData?.primary_email} className="primary-link">{leadData?.primary_email || 'N/A'}</a>
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'primary_email', 'Email', 'isEmail', false)}
                                        validationMessage={errorMessages['primary_email']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['primary_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['primary_email']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'phone1') }}
                                        onCancel={() => { onCancelEdit('phone1') }}
                                        value={leadData?.phone1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone" value={leadData?.phone1} errorMessages={errorMessages['phone1']} maxLength={25} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Phone" element={
                                            leadData?.phone1 ? <a href={"tel:" + leadData?.phone1} className="primary-link">{leadData?.phone1 || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'phone1', 'Phone', 'phone', false)}
                                        validationMessage={errorMessages['phone1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['phone1'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone1']}</span>}
                                </Grid>

                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-12">Lead Details</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'description') }}
                                        onCancel={() => { onCancelEdit('description') }}
                                        value={leadData?.description || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description" value={leadData?.description} errorMessages={errorMessages['description']} maxLength={150} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Description" value={leadData?.description || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'description', 'Description', '', false, '2', '150')}
                                        validationMessage={errorMessages['description']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['description'] && <span className="easy-edit-validation-error">{backendErrorMessages['description']}</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'product_id', 'select') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={selectedData?.ProductName?.join(', ') || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomMultiSelect setAllowEditInline={setAllowEditInline} isMultiSelect={true} label="Product" fieldValue={selectedProductIds || []}
                                                items={
                                                    productList && productList.length > 0 && productList.map((product) => (
                                                        { key: product.id, value: product.name }
                                                    ))
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Product" value={selectedData?.ProductName?.join(', ')} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['product_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['product_id']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'lead_source') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={leadData?.lead_source || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Source" fieldValue={leadData?.lead_source || ''}
                                                items={
                                                    leadSourceArray && leadSourceArray.length > 0 && leadSourceArray.map((source) => (
                                                        { value: source.title, option: source.title }
                                                    ))
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Source" value={leadData?.lead_source || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['lead_source'] && <span className="easy-edit-validation-error">{backendErrorMessages['lead_source']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-12">Company Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'organization_name') }}
                                        onCancel={() => { onCancelEdit('organization_name') }}
                                        value={leadData?.organization_name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Name" value={leadData?.organization_name} errorMessages={errorMessages['organization_name']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Company Name" value={leadData?.organization_name || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'organization_name', 'Company Name')}
                                        validationMessage={errorMessages['organization_name']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['organization_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_name']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'organization_email') }}
                                        onCancel={() => { onCancelEdit('organization_email') }}
                                        value={leadData?.organization_email || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Email" value={leadData?.organization_email} errorMessages={errorMessages['organization_email']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Company Email" element={
                                            leadData?.organization_email ? <a href={"mailto:" + leadData?.organization_email} className="primary-link">{leadData?.organization_email || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'organization_email', 'Company Email', 'isEmail', false)}
                                        validationMessage={errorMessages['organization_email']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['organization_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_email']}</span>}
                                </Grid>

                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'organization_phone1') }}
                                        onCancel={() => { onCancelEdit('organization_phone1') }}
                                        value={leadData?.organization_phone1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Phone" value={leadData?.organization_phone1} errorMessages={errorMessages['organization_phone1']} maxLength={25} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Company Phone" element={
                                            leadData?.organization_phone1 ? <a href={"tel:" + leadData?.organization_phone1} className="primary-link">{leadData?.organization_phone1}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'organization_phone1', 'Company Phone', 'phone', false)}
                                        validationMessage={errorMessages['organization_phone1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['organization_phone1'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_phone1']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'department') }}
                                        onCancel={() => { onCancelEdit('department') }}
                                        value={leadData?.department || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Department" value={leadData?.department} errorMessages={errorMessages['department']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Department" value={leadData?.department || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'department', 'Department', '', false)}
                                        validationMessage={errorMessages['department']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['department'] && <span className="easy-edit-validation-error">{backendErrorMessages['department']}</span>}
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'organization_website') }}
                                        onCancel={() => { onCancelEdit('organization_website') }}
                                        value={leadData?.organization_website || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Website" value={leadData?.organization_website} errorMessages={errorMessages['organization_website']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Company Website" element={
                                            leadData?.organization_website ? <a href={leadData?.organization_website} className="primary-link">{leadData?.organization_website || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'organization_website', 'Company Website', 'isWebsite', false)}
                                        validationMessage={errorMessages['organization_website']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['organization_website'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_website']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-12">Contact Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item lg={6} xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'address1') }}
                                        onCancel={() => { onCancelEdit('address1') }}
                                        value={leadData?.address1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 1" value={leadData?.address1} errorMessages={errorMessages['address1']} maxLength={100} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Address 1" value={leadData?.address1 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'address1', 'Address 1', '', false, 2, 100)}
                                        validationMessage={errorMessages['address1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['address1'] && <span className="easy-edit-validation-error">{backendErrorMessages['address1']}</span>}
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'address2') }}
                                        onCancel={() => { onCancelEdit('address2') }}
                                        value={leadData?.address2 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 2" value={leadData?.address2} errorMessages={errorMessages['address2']} maxLength={100} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Address 2" value={leadData?.address2 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'address2', 'Address 2', '', false, 2, 100)}
                                        validationMessage={errorMessages['address2']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['address2'] && <span className="easy-edit-validation-error">{backendErrorMessages['address2']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'city') }}
                                        onCancel={() => { onCancelEdit('city') }}
                                        value={leadData?.city || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="City" value={leadData?.city} errorMessages={errorMessages['city']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="City" value={leadData?.city || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'city', 'City', '', false)}
                                        validationMessage={errorMessages['city']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['city'] && <span className="easy-edit-validation-error">{backendErrorMessages['city']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'state') }}
                                        onCancel={() => { onCancelEdit('state') }}
                                        value={leadData?.state || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="State" value={leadData?.state} errorMessages={errorMessages['state']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="State" value={leadData?.state || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'state', 'State', '', false)}
                                        validationMessage={errorMessages['state']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['state'] && <span className="easy-edit-validation-error">{backendErrorMessages['state']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                        onCancel={() => { onCancelEdit('zipcode') }}
                                        value={leadData?.zipcode || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Zipcode" value={leadData?.zipcode} errorMessages={errorMessages['zipcode']} maxLength={10} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Zipcode" value={leadData?.zipcode || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'zipcode', 'Zipcode', '', false, 2, 10)}
                                        validationMessage={errorMessages['zipcode']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['zipcode'] && <span className="easy-edit-validation-error">{backendErrorMessages['zipcode']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'country_id') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={selectedData?.Countryname?.[0] || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Country" fieldValue={leadData?.country_id || ''}
                                                items={
                                                    countryArray && countryArray.length > 0 && countryArray.map((country) => (
                                                        { value: country.id, option: country.country_name }
                                                    ))
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Country" value={selectedData?.Countryname?.[0] || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['country_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['country_id']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-12">Social Media</Typography>
                            <Grid container spacing={1.5}>
                                <Grid item xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'facebook_url') }}
                                        onCancel={() => { onCancelEdit('facebook_url') }}
                                        value={leadData?.facebook_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Facebook" value={leadData?.facebook_url} errorMessages={errorMessages['facebook_url']} maxLength={255} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Facebook" element={
                                            leadData?.facebook_url ? <a href={leadData?.facebook_url} className="primary-link">{leadData?.facebook_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'facebook_url', 'Facebook', 'isWebsite', false, 2, 255)}
                                        validationMessage={errorMessages['facebook_url']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['facebook_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['facebook_url']}</span>}
                                </Grid>
                                <Grid item xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'linkedin_url') }}
                                        onCancel={() => { onCancelEdit('linkedin_url') }}
                                        value={leadData?.linkedin_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="LinkedIn" value={leadData?.linkedin_url} errorMessages={errorMessages['linkedin_url']} maxLength={255} />}
                                        displayComponent={<FormGroupStatic easyEdit label="LinkedIn" element={
                                            leadData?.linkedin_url ? <a href={leadData?.linkedin_url} className="primary-link">{leadData?.linkedin_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'linkedin_url', 'LinkedIn', 'isWebsite', false, 2, 255)}
                                        validationMessage={errorMessages['linkedin_url']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick()}
                                    />
                                    {backendErrorMessages['linkedin_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['linkedin_url']}</span>}
                                </Grid>
                                <Grid item xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'twitter_url') }}
                                        onCancel={() => { onCancelEdit('twitter_url') }}
                                        value={leadData?.twitter_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Twitter" value={leadData?.twitter_url} errorMessages={errorMessages['twitter_url']} maxLength={255} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Twitter" element={
                                            leadData?.twitter_url ? <a href={leadData?.twitter_url} className="primary-link">{leadData?.twitter_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'twitter_url', 'Twitter', 'isWebsite', false, 2, 255)}
                                        validationMessage={errorMessages['twitter_url']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['twitter_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['twitter_url']}</span>}
                                </Grid>
                                <Grid item xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'instagram_url') }}
                                        onCancel={() => { onCancelEdit('instagram_url') }}
                                        value={leadData?.instagram_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Instagram" value={leadData?.instagram_url} errorMessages={errorMessages['instagram_url']} maxLength={255} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Instagram" element={
                                            leadData?.instagram_url ? <a href={leadData?.instagram_url} className="primary-link">{leadData?.instagram_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'instagram_url', 'Instagram', 'isWebsite', false, 2, 255)}
                                        validationMessage={errorMessages['instagram_url']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['instagram_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['instagram_url']}</span>}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xxl={4} xs={12}>
                        {
                            leadData && (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ActivityList handleReloadFunc={handleReloadFunc} type='lead' dataId={leadId} userPermissions={userPermissions} />
                                </Suspense>
                            )
                        }
                    </Grid>

                    <Grid item xs={12}>
                        {
                            leadData && (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <NoteList handleReloadFunc={handleReloadFunc} type='lead' dataId={leadId} />
                                </Suspense>
                            )
                        }
                    </Grid>
                </Grid >}


            {
                convertLeadToDealDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ConvertLeadToDealDialog
                            open={convertLeadToDealDialogOpen}
                            onClose={closeConvertLeadToDealDialog}
                            handleReloadFunc={handleReloadFunc}
                            leadData={converLeadState}
                        />
                    </Suspense>
                )
            }



        </>
    );
}

export default LeadDetails;