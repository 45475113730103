import React from "react";
import { displayCurrencySymbol, displayDate } from "../../utils/Utils";
import moment from "moment-timezone";

const FormGroupStaticAmount = props => {
    return (
        <>
            <div className={"form-group-static " + (props.easyEdit ? 'easy-edit' : '')}>
                <label className="control-label-static">{props.label}</label>
                <p className={"form-control-static " + (props.value === 'N/A' ? 'text-secondary' : '')}>
                    {
                        props.label === 'Deal Amount' ?
                            props.value ? displayCurrencySymbol(props.value, props.currencyCode, props.currencySymbol, props.currencyStringValue) : ''
                            :
                            props.label === 'Deal Probability' ?
                                props.value + ' %'
                                :
                                props.label === 'Price' ?
                                    props.value ? displayCurrencySymbol(props.value, props.currencyCode, props.currencySymbol, props.currencyStringValue) : ''
                                    :
                                    props.label === 'Tax' ?
                                        props.value ? displayCurrencySymbol(props.value, props.currencyCode, props.currencySymbol, props.currencyStringValue) : ''
                                        :
                                        props.label === 'Expected Closing Date' ?
                                            props?.value ? displayDate(moment.utc(props.value).tz(props?.preTimeZoneKey || "Asia/Calcutta"
                                            ).format(props?.preferredDateFormat || 'YYYY-MM-DD'), 'half') : null
                                            :
                                            props.label === 'Valid Until' ?
                                                props?.value && props.value !== '0000-00-00 00:00:00"' ? displayDate(moment.utc(props.value).tz(props?.preTimeZoneKey || "Asia/Calcutta"
                                                ).format(props?.preferredDateFormat || 'YYYY-MM-DD'), 'half') : null
                                                :
                                                props.value

                    }
                </p>
            </div>
        </>
    );
}
export default FormGroupStaticAmount;