import { Button, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { loginUser } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

function TopMenu(props) {
    const navigate = useNavigate();

    const loginUserDetail = useSelector(loginUser);
    const user = loginUserDetail?.user || [];

    return (

        <>
            {
                props?.page === 'overview' &&
                <Stack spacing={0.5} direction={'row'} className="page-tabs">
                    <Button variant="contained" color="secondary" size='small'>Overview</Button>
                    {
                        user && user.real_time_location_traking === 'yes' &&
                        <Button variant="text" size='small' onClick={e => { navigate('/reports/track-users'); }}>Track Users</Button>
                    }
                    {
                        user && user.lead_location_traking === 'yes' &&
                        <Button variant="text" size='small' onClick={e => { navigate('/reports/visit-log-activities'); }}>Visit Log Activities</Button>
                    }
                </Stack>
            }

            {
                props?.page === 'track' &&
                <Stack spacing={0.5} direction={'row'} className="page-tabs">
                    <Button variant="text" size='small' onClick={() => navigate('/reports')}>Overview</Button>
                    {
                        user && user.real_time_location_traking === 'yes' &&
                        <Button variant="contained" color="secondary" size='small'>Track Users</Button>
                    }
                    {
                        user && user.lead_location_traking === 'yes' &&
                        <Button variant="text" size='small' onClick={() => navigate('/reports/visit-log-activities')}>Visit Log Activities</Button>
                    }
                </Stack>
            }

            {
                props?.page === 'activity' &&
                <Stack spacing={0.5} direction={'row'} className="page-tabs">
                    <Button variant="text" size='small' onClick={e => { navigate('/reports'); }}>Overview</Button>
                    {
                        user && user.real_time_location_traking === 'yes' &&
                        <Button variant="text" size='small' onClick={e => { navigate('/reports/track-users'); }}>Track Users</Button>
                    }
                    {
                        user && user.lead_location_traking === 'yes' &&
                        <Button variant="contained" color="secondary" size='small'>Visit Log Activities</Button>
                    }
                </Stack>
            }
        </>
    );
}

export default TopMenu;