import Swal from "sweetalert2";
import axiosInstance from "../config/axiosConfig";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";

// For calendor use
export const displayCalendorDateFormate = (date) => {
    const formatMap = {
        'YYYY-MMM-DD': 'yyyy/MMM/dd',
        'YYYY-MM-DD': 'yyyy/MM/dd',
        'DD-MMM-YYYY': 'dd/MMM/yyyy',
        'DD-MM-YYYY': 'dd/MM/yyyy',
        'YYYY-DD-MMM': 'yyyy/dd/MMM',
        'YYYY-DD-MM': 'yyyy/dd/MM',
        'MMM-DD-YYYY': 'MMM/dd/yyyy',
        'MM-DD-YYYY': 'MM/dd/yyyy',
    };

    const calendorFormate = date?.split(' ') || '';
    return formatMap[calendorFormate[0]] || '';
};

//For listing use
export const displayListDateFormate = (date) => {
    const formatMap = {
        'YYYY-MMM-DD': 'yyyy/MM/DD',
        'DD-MMM-YYYY': 'DD/MM/yyyy',
        'YYYY-DD-MMM': 'yyyy/DD/MM',
        'MMM-DD-YYYY': 'MM/DD/yyyy',
    };

    const calendorFormate = date?.split(' ') || '';
    return formatMap[calendorFormate[0]] || '';
};


// Function to capitalize a string
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};


// Function to display date on list page (half Or Full)
export const displayDate = (date, type = null) => {
    const halfDate = date?.split(' ')
    if (date && type === 'half') {
        return halfDate.length >= 2 ? halfDate[0] : ''
    } else {
        return date;
    }
};


// Function to display Currency symbol
export const displayCurrencySymbol = (amount, currencyCode, currencySymbol, currencyStringValue = null) => {
    let num = parseFloat(amount);
    const myObj = {
        style: "currency",
        currency: currencyCode
    }
    if (amount && currencyCode && currencySymbol) {
        return num ? currencyStringValue ? num.toLocaleString(currencyStringValue, myObj) : currencySymbol + num.toLocaleString(myObj) : 'N/A'
    } else {
        return ''
    }

};


// Function to Handle validation on edit page
export const validateField = (value, fieldName, displayName, type = null, isrequired = true, min = 2, max = 50) => {
    const fieldDisplayName = displayName;

    if (!isrequired && (!value || value === 'N/A')) {
        return '';
    }

    if (isrequired) {
        if (!value) return `Enter ${fieldDisplayName}`;
    }


    // mobile-specific validation
    if (type && type === 'phone') {
        if (value && value.length > 25) {
            return `Allow 25 characters only for ${fieldDisplayName}`;
        } else {
            return ''
        }
    }


    const splString = value && value.split(' ');
    if (value.length > max) return `Allow ${max} characters only for ${fieldDisplayName}`;
    if (value.length < min) return `Enter at least ${min} characters for ${fieldDisplayName}`;
    if (value.length === min && splString.length >= min) return `Enter at least ${min} characters, space not allowed for ${fieldDisplayName}`;

    // Email-specific validation
    if (type && type === 'isEmail' && !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value)) {
        return `Please enter a valid email address`;
    }

    // Website-specific validation
    const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    if (type && type === 'isWebsite' && !httpRegex.test(value)) {
        return `Please enter a valid website`;
    }

    return '';

};

export const DeleteDialog = withReactContent(Swal);

export const DeleteDialogConfig = ({ confirm, cancel, confirmMessage = null, message = null, buttomName = null }) => {
    return {
        html: `
      <div class="delete-dialog-swal">
      <div class="icon-wrapper">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 3rem; height: 3rem;"><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path></svg>
                </div>
        <h2 class="title">${confirmMessage ? confirmMessage : 'Confirm Deletion'}</h2>
        <p class="message">${message ? message : 'Are you sure you want to delete this item? This action cannot be undone.'}</p> 
      <div class="btn-wrapper">
        <button id="mui-confirm-button" class="swal-confirm-btn">${buttomName ? buttomName : 'Delete'}</button>
        <button id="mui-cancel-button" class="swal-cancel-btn">Cancel</button>
      </div>
      </div>
    `,
        showCancelButton: false, // Disable default buttons
        showConfirmButton: false,
        didOpen: () => {
            const confirmButton = document.getElementById("mui-confirm-button");
            const cancelButton = document.getElementById("mui-cancel-button");

            confirmButton.addEventListener("click", () => {
                confirm();
                Swal.close(); // Close the dialog
            });

            cancelButton.addEventListener("click", () => {
                cancel();
                Swal.close(); // Close the dialog
            });
        },
    }
};


// get main domain host for store in cookies
export const getMainDomain = (getURL = null) => {
    const url = getURL ? getURL : window.location.href
    try {
        const parsedUrl = new URL(url);
        const hostname = parsedUrl.hostname;

        // Split the hostname into parts
        const parts = hostname.split('.');

        if (hostname.includes('localhost')) {
            return null;
        }

        // Handle custom cases like 'app.jignesh' or 'app.localhost'
        if (parts.length === 2) {
            // If the hostname is like 'app.jignesh', just return 'jignesh'
            return '.' + parts[1];
        }

        // Handle standard domains like 'app.google.com' or 'sub.example.com'
        if (parts.length > 2) {
            return `.${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
        }

        // Return the entire hostname if it's just one part (e.g., 'localhost')
        return hostname;
    } catch (error) {
        console.error('Invalid URL:', error);
        return null;
    }
};


// if get new subdomain from url, than replace with existing subdomain
export const replaceSubdomain = (url, newSubdomain) => {
    try {
        const urlObj = new URL(url);

        // Split the hostname into parts
        const parts = urlObj.hostname.split('.');

        // Check if it's a typical hostname (localhost cases can vary in structure)
        const isLocalhost = parts.includes('localhost');

        // If there are more than two parts and it's not localhost, we have a subdomain to replace
        if (parts.length > 2 && !isLocalhost) {
            // Replace the first part (subdomain) with the new subdomain
            parts[0] = newSubdomain;
        } else if (isLocalhost && parts[0] !== 'localhost') {
            // If it has a subdomain and ends with localhost, replace it
            parts[0] = newSubdomain;
        } else if (!isLocalhost && parts.length === 2) {
            // If no subdomain exists (e.g., "domain.com"), prepend the new subdomain
            parts.unshift(newSubdomain);
        } else if (isLocalhost && parts.length === 1) {
            // If it’s just localhost, prepend the subdomain
            parts.unshift(newSubdomain);
        }

        // Reconstruct the hostname
        urlObj.hostname = parts.join('.');

        return urlObj.toString();
    } catch (error) {
        console.error('Invalid URL:', error);
        return url; // Return the original URL if there's an error
    }
    // Example usage:
    // const originalUrl = 'http://app.localhost:3004/dashboard';
    // const newUrl = replaceSubdomain(originalUrl, 'newsubdomain');
    // console.log(newUrl); // Outputs: 'http://newsubdomain.localhost:3004/dashboard'
};

// get subdomain from url
export const getSubdomain = (url) => {
    try {
        // Create a URL object to easily parse the URL components
        const { hostname } = new URL(url);

        // Split the hostname into parts
        const parts = hostname.split('.');

        // Check for specific cases like 'localhost' with subdomains
        if (hostname.includes('localhost')) {
            // Handle local development URLs like 'app.localhost:3004'
            if (parts.length > 1 && parts[0] !== 'localhost') {
                return parts[0]; // Return the first part as subdomain
            }
        } else if (parts.length > 2) {
            // For standard URLs, return the subdomain (all parts except the last two)
            return parts.slice(0, -2).join('.');
        }

        // No subdomain found or invalid URL format
        console.log('Oops! No subdomain found.');
        return '';
    } catch (error) {
        console.error('Oops! Something went wrong. The URL is invalid:', error);
        return '';
    }
    // Example usage:
    // console.log(getSubdomain('http://app.localhost:3004/dashboard')); // Output: app
    // console.log(getSubdomain('http://newsubdomain.localhost:3004/dashboard')); // Output: newsubdomain
    // console.log(getSubdomain('https://app.solvcrm.co/leads')); // Output: app
    // console.log(getSubdomain('https://localhost:3004/dashboard')); // Output: ''
};
