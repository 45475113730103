import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Dialog, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../../components/common/EasyEditButtons";
import FormGroupStatic from "../../../components/common/FormGroupStatic";
import EasyEditCustomTextbox from "../../../components/common/EasyEditCustomTextbox";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditDateTime from "../../../components/common/EasyEditDateTime";
import EasyEditCustomSelect from "../../../components/common/EasyEditCustomSelect";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { validateField } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useLoader } from "../../../components/context/LoaderContext";
import { Navigate } from "react-router-dom";

function TaskDetailsDialog({ onClose, open, taskId, handleReloadFunc, priorityList, statusList }) {
    const { showLoader, hideLoader, loading } = useLoader();
    const [allowEditInline, setAllowEditInline] = useState(true);
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [task, setTask] = useState();
    const [listData, setListData] = useState(null);


    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [taskId]);

    const getData = async () => {
        showLoader()
        await axiosInstance.get('api/task/edit/' + taskId).then((response) => {
            if (response.status === 200) {
                setTask(response.data?.data?.record);
                setListData(response?.data?.data);
                hideLoader();
            }
        }).catch((error) => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                    Navigate("/tasks");
                }
            }
        });
    }


    const handleSave = async (value, name, type = null) => {
        const data = {
            id: taskId,
            name: task?.name || '',
            description: task?.description,
            type: task?.lead_id ? "lead" : "deal",
            leadId: task?.lead_id ? task?.lead_id : "",
            dealId: task?.deal_id ? task?.deal_id : "",
            end_date: task?.end_date ? moment.utc(task?.end_date).tz(preTimeZoneKey).format(preferredDateFormat) : moment(),
            priority: task?.priority,
            activity_status: task?.activity_status,
            [name]: value,
        };

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(task, name);

        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/task/update", data).then(response => {
                if (response.status === 200) {
                    hideLoader();
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(true)
                    handleReloadFunc();
                    getData(task?.id);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        } else {
            setAllowEditInline(true)
        }

    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };


    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Task Details</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                {!loading &&
                    <>
                        <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSave(value, 'name') }}
                                    onCancel={() => setAllowEditInline(true)}
                                    value={task?.name || "N/A"}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={() => setAllowEditInline(false)} label="Subject" value={task?.name} errorMessages={errorMessages['name']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Subject" value={task?.name || ''} />}
                                    onValidate={(value) => handleValidation(value, 'name', 'Subject')}
                                    validationMessage={errorMessages['name']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                            </Grid>
                            <Grid item xs={12}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSave(value, 'end_date') }}
                                    onCancel={() => setAllowEditInline(true)}
                                    value={task?.end_date ? moment.utc(task?.end_date).tz(preTimeZoneKey).format(preferredDateFormat) : null}
                                    allowEdit={allowEditInline}
                                    editComponent={
                                        <EasyEditDateTime
                                            setAllowEditInline={setAllowEditInline}
                                            label="Due Date"
                                            value={task?.end_date}
                                            errorMessages={errorMessages['end_date']}
                                            preferredDateFormat={preferredDateFormat}
                                            setParentValue={(value) => {
                                                handleSave(value, 'end_date');
                                            }}
                                        />}
                                    displayComponent={<FormGroupStatic easyEdit label="Due Date" value={task?.end_date ? moment.utc(task?.end_date).tz(preTimeZoneKey).format(preferredDateFormat) : null} preTimeZoneKey={preTimeZoneKey} preferredDateFormat={preferredDateFormat} />}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['end_date'] && <span className="easy-edit-validation-error">{backendErrorMessages['end_date']}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSave(value, 'priority') }}
                                    onCancel={() => setAllowEditInline(true)}
                                    value={task?.priority || "N/A"}
                                    allowEdit={allowEditInline}
                                    editComponent={
                                        <EasyEditCustomSelect setAllowEditInline={setAllowEditInline} label="Priority" fieldValue={task?.priority || ''}
                                            items={
                                                Object.entries(priorityList).map(([key, value]) => (
                                                    { value: key, option: value }
                                                ))
                                            }
                                        />
                                    }
                                    displayComponent={<FormGroupStatic easyEdit label="Priority" element={
                                        <>
                                            {task?.priority === 'low' && <span className="status-badge success">{task?.priority || 'N/A'}</span>}
                                            {task?.priority === 'medium' && <span className="status-badge warning">{task?.priority || 'N/A'}</span>}
                                            {task?.priority === 'high' && <span className="status-badge danger">{task?.priority || 'N/A'}</span>}
                                            {task?.priority === null && <span className="status-badge info">{'N/A'}</span>}
                                        </>
                                    } />}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['priority'] && <span className="easy-edit-validation-error">{backendErrorMessages['priority']}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSave(value, 'activity_status') }}
                                    onCancel={() => setAllowEditInline(true)}
                                    value={task?.activity_status || "N/A"}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Status" fieldValue={task?.activity_status || 'N/A'}
                                        items={
                                            Object.entries(statusList).map(([key, value]) => (
                                                { value: key, option: value }
                                            ))
                                        }
                                    />
                                    }
                                    displayComponent={<FormGroupStatic easyEdit label="Status" element={
                                        <>
                                            {task?.activity_status === 'not-started' && <span className="status-badge">{task?.activity_status || 'N/A'}</span>}
                                            {task?.activity_status === 'in-progress' && <span className="status-badge warning">{task?.activity_status || 'N/A'}</span>}
                                            {task?.activity_status === 'completed' && <span className="status-badge success">{task?.activity_status || 'N/A'}</span>}
                                            {task?.activity_status === null && <span className="status-badge info">{'N/A'}</span>}
                                        </>
                                    } />}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['activity_status'] && <span className="easy-edit-validation-error">{backendErrorMessages['activity_status']}</span>}
                            </Grid>

                            <Grid item xs={12}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSave(value, 'description') }}
                                    onCancel={() => setAllowEditInline(true)}
                                    value={task?.description || "N/A"}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description" value={task?.description} errorMessages={errorMessages['description']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Description" value="Follow-up Call with Lead Description" />}
                                    onValidate={(value) => handleValidation(value, 'description', 'Description', '', false, 2, 150)}
                                    validationMessage={errorMessages['description']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['description'] && <span className="easy-edit-validation-error">{backendErrorMessages['description']}</span>}
                            </Grid>

                            <Grid item xs={12}>
                                <FormGroupStatic easyEdit label="Related to"
                                    value={
                                        listData?.relatedDisplayName && listData.relatedDisplayName.name ? (
                                            listData.relatedDisplayName.type === 'Lead' ? (
                                                <a href={`/lead-detail/${listData.relatedDisplayName.id}`}>
                                                    {listData.relatedDisplayName.name}
                                                </a>
                                            ) : listData.relatedDisplayName.type === 'Deal' ? (
                                                <a href={`/deal-detail/${listData.relatedDisplayName.id}`}>
                                                    {listData.relatedDisplayName.name}
                                                </a>
                                            ) : (
                                                listData.relatedDisplayName.name
                                            )
                                        ) : 'N/A'
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormGroupStatic easyEdit label="Closed Time"
                                    value={task?.closed_time
                                        ? moment.utc(task.closed_time)
                                            .tz(preTimeZoneKey)
                                            .format(preferredDateFormat)
                                        : "N/A"}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <FormGroupStatic easyEdit label="Created by" value={listData?.contactName?.salutation + " " + listData?.contactName?.first_name + " " + listData?.contactName?.last_name + " " + moment.utc(task?.created_at).tz(preTimeZoneKey).format(preferredDateFormat) || 'N/A'} />
                            </Grid> */}
                        </Grid>
                    </>
                }
            </Dialog>
        </>
    );
}

export default TaskDetailsDialog;