import { mdiEmailOutline, mdiPencilOutline, mdiPhoneOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Grid, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import AvatarInitials from "../../../components/common/AvatarInitials";
import { Link, useNavigate } from "react-router-dom";
import CreateNewContactDialog from "../../account/components/CreateNewcontactDialog";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import EmptyState from "../../../components/common/EmptyState";
import { DeleteDialog, DeleteDialogConfig } from "../../../utils/Utils";

function DealContactList({ account_id, handleReloadParentFunc, userPermissions }) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();

    //default data for list api
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);

    const [reloadPage, setReloadPage] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [salutationList, setSalutationList] = useState([]);
    const [editcontactID, setEditcontactID] = useState();

    useEffect(() => {
        if (account_id) {
            async function fetchData() {
                showLoader();
                await getData();
            }
            fetchData();
        }
    }, [account_id, reloadPage]);

    const getData = async () => {
        const params = {
            per_page: perPage,
            page: currentPage,
            account_id: account_id
        };

        await axiosInstance.get(`api/contact?page=${currentPage + 1}`, { params }).then(response => {
            if (response.status === 200) {
                setContacts(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setSalutationList(response?.data?.data?.salutationArray)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/deal");
                    }
                }
            });
    };

    const handleReloadFunc = () => {
        setReloadPage(reloadPage ? false : true);
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    const [createNewContactDialogOpen, setCreateNewContactDialogOpen] = React.useState(false);
    const openCreateNewContactDialog = (id = null) => {
        setEditcontactID(id)
        setCreateNewContactDialogOpen(true);
    }
    const closeCreateNewContactDialog = () => {
        setEditcontactID('')
        setCreateNewContactDialogOpen(false);
    }

    //=======Start::Write a code for delete deal contact=======//
    async function deleteData(id) {
        const data = {
            id: id,
            account_id: account_id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/contact/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    handleReloadParentFunc()
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete deal contact=======//

    return (
        <>
            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Contacts</h6>
                    {userPermissions && userPermissions.includes("contact_create") &&
                        <Button color="secondary" size="small" className="action" onClick={() => openCreateNewContactDialog()}>
                            <span>
                                <Icon path={mdiPlus} color={'currentColor'} size={1} />
                                <span>Add</span>
                            </span>
                        </Button>
                    }
                </div>

                {contacts && contacts.length > 0 && (
                    <ul className="primary-list">
                        {
                            contacts.map((contact, index) => {
                                return (
                                    <li key={index + ''}>
                                        <Link to={userPermissions?.includes("contact_edit") ? '/contact-detail/' + contact?.id : '#'} className="card">
                                            <Grid container alignItems={'flex-start'} spacing={1.5}>
                                                <Grid item xs="auto">
                                                    <AvatarInitials name={contact?.first_name + ' ' + contact?.last_name} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant='title-medium-bold' component={'h6'}>{contact?.salutation ? contact.salutation + '. ' : ''}{contact?.first_name + ' ' + contact?.last_name}</Typography>
                                                    <ul className='inline-details'>
                                                        <li><Tooltip title="Title"><span>{contact?.title || 'N/A'}</span></Tooltip></li>
                                                        <li>
                                                            <Tooltip title="Email">
                                                                <span>
                                                                    <Icon path={mdiEmailOutline} color="currentColor" size={1} />{contact?.primary_email || contact?.secondry_email || 'N/A'}
                                                                </span>
                                                            </Tooltip>
                                                        </li>
                                                        <li>
                                                            <Tooltip title="Phone">
                                                                <span>
                                                                    <Icon path={mdiPhoneOutline} color="currentColor" size={1} /> {contact?.phone1 || contact?.phone1 || 'N/A'}
                                                                </span>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                        <Stack direction={'row'} className='actions'>
                                            {userPermissions && userPermissions.includes("contact_edit") &&
                                                <Tooltip title="Edit">
                                                    <span>
                                                        <button type="button" className='icon-button' onClick={() => openCreateNewContactDialog(contact?.id)}>
                                                            <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            }
                                            {userPermissions && userPermissions.includes("contact_delete") &&
                                                <Tooltip title="Delete">
                                                    <button type="button" className='icon-button' onClick={() => {
                                                        deleteData(contact?.id)
                                                    }}>
                                                        <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                    </button>
                                                </Tooltip>
                                            }
                                        </Stack>
                                    </li>)
                            })}
                    </ul>
                )}

                {(!contacts || contacts.length === 0) && !loading &&
                    <EmptyState type={'contact'} hideActionBtn />
                }

                {
                    contacts?.length > 0 &&
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                }

            </div>
            {
                createNewContactDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CreateNewContactDialog
                            open={createNewContactDialogOpen}
                            onClose={closeCreateNewContactDialog}
                            handleReloadFunc={handleReloadFunc}
                            account_id={account_id}
                            salutationList={salutationList}
                            contactID={editcontactID}
                            handleReloadParentFunc={handleReloadParentFunc}
                        />
                    </Suspense>
                )
            }
        </>
    );
}

export default DealContactList;