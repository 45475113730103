import React from 'react';
import { FixedSizeList as List, areEqual } from "react-window";
import { useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { Grid, Tooltip, Typography } from '@mui/material';
import AvatarInitials from '../../../components/common/AvatarInitials';
import moment from 'moment-timezone';
import { mdiDomain, mdiPhoneOutline } from '@mdi/js';
import Icon from '@mdi/react';

export default React.memo(({ cards, droppableId, setCardsInLane }) => {
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const CardItem = ({ index, style }) => {
        const card = cards[index];
        return (
            <Draggable draggableId={card.id} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...style, ...provided.draggableProps.style }}
                    >
                        <Link to={`/lead-detail/${card?.id}`} className="card deal-lead-card">
                            <Grid container spacing={1.5} alignItems={'flex-start'} flexWrap={'nowrap'}>
                                <Grid item xs={'auto'} className="flex-shrink-0">
                                    <AvatarInitials name={card?.name || 'N/A'} />
                                </Grid>
                                <Grid item xs sx={{ minWidth: 0 }}>
                                    <Typography variant="title-medium-bold" className='text-truncate' component={'h6'}>{card?.salutation || ''} {card?.name || 'N/A'}</Typography>
                                    <Typography variant="body-medium" component={'p'} className="title text-truncate">{card?.title || 'N/A'}</Typography>
                                    <Typography variant="body-medium" component={'p'} className='text-truncate'>{card?.date ? moment.utc(card.date).tz(preTimeZoneKey).format(preferredDateFormat) : "N/A"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className="bottom-block" flexWrap={'nowrap'}>
                                <Grid item xs='auto'>
                                    <Tooltip title="Company Name">
                                        <span>
                                            <Icon path={mdiDomain} color={'currentColor'} size={1} />
                                            <span>{card?.organization || 'N/A'}</span>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs='auto' sx={{ marginLeft: 'auto' }}>
                                    <Tooltip title="Phone">
                                        <span>
                                            <Icon path={mdiPhoneOutline} color={'currentColor'} size={1} />
                                            <span>{card?.phone || 'N/A'}</span>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Link>
                    </div>
                )}
            </Draggable>
        );
    };

    return (
        <Droppable droppableId={droppableId} mode="virtual" renderClone={(provided, snapshot, rubric) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={provided.draggableProps.style}
            >
                <div className="card deal-lead-card">
                    <Grid container spacing={1.5} alignItems={'flex-start'} flexWrap={'nowrap'}>
                        <Grid item xs={'auto'} className="flex-shrink-0">
                            <AvatarInitials name={cards[rubric.source.index].name || 'N/A'} />
                        </Grid>
                        <Grid item xs sx={{ minWidth: 0 }}>
                            <Typography variant="title-medium-bold" className='text-truncate' component={'h6'}>{cards[rubric.source.index].salutation || ''} {cards[rubric.source.index].name || 'N/A'}</Typography>
                            <Typography variant="body-medium" component={'p'} className="title text-truncate">{cards[rubric.source.index].title || 'N/A'}</Typography>
                            <Typography variant="body-medium" component={'p'} className='text-truncate'>{cards[rubric.source.index].date ? moment.utc(cards[rubric.source.index].date).tz(preTimeZoneKey).format(preferredDateFormat) : "N/A"}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="bottom-block" flexWrap={'nowrap'}>
                        <Grid item xs='auto'>
                            <Tooltip title="Company Name">
                                <span>
                                    <Icon path={mdiDomain} color={'currentColor'} size={1} />
                                    <span>{cards[rubric.source.index].organization || 'N/A'}</span>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs='auto' sx={{ marginLeft: 'auto' }}>
                            <Tooltip title="Phone">
                                <span>
                                    <Icon path={mdiPhoneOutline} color={'currentColor'} size={1} />
                                    <span>{cards[rubric.source.index].phone || 'N/A'}</span>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )}>
            {(provided, snapshot) => (
                <List
                    height={window.innerHeight - 213}
                    itemCount={cards.length}
                    itemSize={139}
                    width={"100%"}
                    outerRef={provided.innerRef}
                >
                    {CardItem}
                </List>
            )}
        </Droppable>
    );
}, areEqual);

