import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, IconButton, InputAdornment, MenuItem, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAttachment, mdiCalendarWeekBeginOutline, mdiClose, mdiMagnify } from "@mdi/js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ViewLocationDialog from "./components/ViewLocationDialog";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { capitalize, displayDate } from "../../utils/Utils";
import AttachmentViewerDialog from "../../components/common/AttachmentViewerDialog";
import TopMenu from "./components/TopMenu";
import EmptyState from "../../components/common/EmptyState";


function VisitLogActivities(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar, loading } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    //add for handle apply button
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const sortOrder = process.env.REACT_APP_DEFAULT_SORT
    const sortBy = 'created_at'

    // State management for search fields
    const [clearType, setClearType] = useState(false);
    const [search, setSearch] = useState();
    const [searchUser, setSearchUser] = useState("All Users");

    const [userLists, setUserLists] = useState([]);
    const [visitLogs, setVisitLogs] = useState();
    const [attachments, setAttachments] = useState([]);
    const [locationDetail, setLocationDetail] = useState({
        latitude: null,
        longitude: null,
        visitedTime: null
    });

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchUser
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        searchUser,
        startDate,
        endDate,
        clearType
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
        searchUser
    ) => {
        const params = {
            search: search,
            user_id: searchUser === "All Users" ? "" : searchUser,
            sort_order: sortOrder,
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/log-visit-activity?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setVisitLogs(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setUserLists(response?.data?.data?.users || []);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchUser
            );
        }
    };

    const HandleSearchUser = (event) => {
        setSearchUser(event.target.value);
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'user') {
            setSearchUser('All Users')
        } else {
            setSearchUser("All Users");
            setSearch('')
            setStartDate(null)
            setEndDate(null)
        }

    }

    //==========Start::Write a code for manage open view location popup===========//
    const [viewLocationDialogOpen, setViewLocationDialogOpen] = React.useState(false);
    const openViewLocationDialog = (lat, long, time) => {
        setLocationDetail({
            latitude: lat,
            longitude: long,
            visitedTime: time ? moment.utc(time).tz(preTimeZoneKey).format(preferredDateFormat) : ""
        })
        setViewLocationDialogOpen(true);
    }
    const closeViewLocationDialog = () => {
        setLocationDetail({
            latitude: '',
            longitude: '',
            visitedTime: ''
        })
        setViewLocationDialogOpen(false);
    }
    //==========End::Write a code for manage open view location popup===========//

    //==========Start::Write a code for manage open attachment popup===========//
    const [attachmentViewerDialogOpen, setAttachmentViewerDialogOpen] = React.useState(false);
    const openAttachmentViewerDialog = (attachment) => {
        setAttachments(attachment)
        setAttachmentViewerDialogOpen(true);
    }
    const closeAttachmentViewerDialog = () => {
        setAttachments([])
        setAttachmentViewerDialogOpen(false);
    }
    //==========End::Write a code for manage open attachment popup===========//

    return (
        <>
            <Helmet>
                <title>Visit Log Activities | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <TopMenu page={'activity'} />

            <Grid container className='page-action-bar'>
                <Grid item xl={'auto'} xs={12}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                        <TextField
                            variant="filled"
                            placeholder="Search logs"
                            name="searchData"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="size-small page-search no-margin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            select
                            value={searchUser}
                            variant="filled"
                            className="size-small no-label no-margin"
                            onChange={HandleSearchUser}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' className='clear-filter'>
                                        {
                                            searchUser && searchUser !== 'All Users' &&
                                            <IconButton onClick={() => clearUserFilter('user')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem key={12224} value="All Users">
                                All Users
                            </MenuItem>
                            {userLists &&
                                userLists.length > 0 &&
                                userLists.map((user, index) => (
                                    <MenuItem key={index} value={user.id}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                        {
                            (search || (searchUser && searchUser !== 'All Users')) &&
                            <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                        }

                    </Stack>
                </Grid>
                <Grid item xl={'auto'} xs={12} sx={{ marginLeft: { xl: 'auto' }, marginTop: { xl: 0, xs: 1.5 } }}>
                    <Grid container spacing={1}>
                        <Grid item xs="auto">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    format={displayDate(preferredDateFormat, 'half')}
                                    views={['year', 'month', 'day']}
                                    slotProps={{
                                        textField: {
                                            variant: 'filled',
                                            name: "start_date",
                                            type: 'text',
                                            placeholder: 'From Date',
                                            className: 'no-label no-margin size-small width-160'
                                        }
                                    }}
                                    name="start_date"
                                    value={startDate}
                                    onChange={(value) => setStartDate(value)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs="auto">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    format={displayDate(preferredDateFormat, 'half')}
                                    views={['year', 'month', 'day']}
                                    minDate={startDate}
                                    slotProps={{
                                        textField: {
                                            variant: 'filled',
                                            name: "end_date",
                                            type: 'text',
                                            placeholder: 'To Date',
                                            className: 'no-label no-margin size-small width-160'
                                        }
                                    }}
                                    name="end_date"
                                    value={endDate}
                                    onChange={(value) => setEndDate(value)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {visitLogs && visitLogs.length > 0 && (
                <ul className="primary-list">
                    {visitLogs.map((log, index) =>
                        <li key={index + ''}>
                            <div className="card">
                                <Typography variant='title-medium-bold' component={'h6'}>{log?.added_by?.name || "N/A"}</Typography>
                                <ul className='inline-details'>
                                    <li><Tooltip title="Type"><span>{log?.relatedToName?.type || "N/A"}</span></Tooltip></li>
                                    <li>
                                        <Tooltip title="Date">
                                            <span>
                                                <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />{log?.end_date
                                                    ? moment
                                                        .utc(log.end_date)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                            </span>
                                        </Tooltip>
                                    </li>
                                </ul>
                                <ul className='inline-details other-info ml-0'>
                                    <li>{log.description}</li>
                                </ul>
                            </div>

                            <Stack direction={'row'} className='actions' alignItems={"center"}>
                                {log?.attachment?.length > 0 &&
                                    <button type="button" className="attachment-btn" onClick={() => openAttachmentViewerDialog(log?.attachment)}>
                                        {log.attachment.length}
                                        <Icon path={mdiAttachment} color={'currentColor'} size={1} />
                                    </button>}
                                <Button size="small" variant="text" onClick={() => openViewLocationDialog(log?.latitude, log?.longitude, log?.end_date)}>View Location</Button>
                            </Stack>
                        </li>
                    )
                    }
                </ul>
            )}
            {(!visitLogs || visitLogs.length === 0) && !loading &&
                <EmptyState type={'log-Activity'} hideActionBtn />
            }

            {
                visitLogs && visitLogs.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }
            {
                attachmentViewerDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AttachmentViewerDialog open={attachmentViewerDialogOpen} onClose={closeAttachmentViewerDialog} attachments={attachments} attachmentType={'/log-visit/'} isDelete={false} isDownload={true} />
                    </Suspense>
                )
            }
            {
                viewLocationDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ViewLocationDialog open={viewLocationDialogOpen} onClose={closeViewLocationDialog} locationDetail={locationDetail} />
                    </Suspense>
                )
            }
        </>
    );
}

export default VisitLogActivities;