import { createSlice } from '@reduxjs/toolkit';
import Cookies from "universal-cookie";
import { getMainDomain } from '../../utils/Utils';

const cookies = new Cookies();

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        permission: null,
        workspace: null,
        notification: null,
        notificationCount: null,
        preferredDateFormat: 'YYYY-MMM-DD h:mm a',
        preTimeZoneKey: 'Asia/Kolkata',
        currencySymbol: '₹',
        currencyCode: 'INR',
        currencyStringValue: 'en-IN',
    },

    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },

        logout: (state, action) => {
            state.user = null
            state.permission = null
            state.workspace = null
            state.notification = null
            state.notificationCount = null

            const hostname = getMainDomain()
            cookies.remove("auth_token", { domain: hostname || '' });
            cookies.remove("auth_name", { domain: hostname || '' });
            cookies.remove("auth_tenant", { domain: hostname || '' });
            cookies.remove("tenant_workspace", { domain: hostname || '' });

            action.payload.navigate('/sign-in');

        },

        permissions: (state, action) => {
            state.permission = action.payload;
        },

        workspaces: (state, action) => {
            state.workspace = action.payload;
        },

        notifications: (state, action) => {
            state.notification = action.payload;
        },

        notificationCount: (state, action) => {
            state.notificationCount = action.payload;
        },

        setTimeAndCurrency: (state, action) => {
            state.preferredDateFormat = action.payload.preferredDateFormat;
            state.preTimeZoneKey = action.payload.preTimeZoneKey;
            state.currencySymbol = action.payload.currencySymbol;
            state.currencyCode = action.payload.currencyCode;
            state.currencyStringValue = action.payload.currencyStringValue;
        },
    },
});

export const { login, logout, permissions, workspaces, notifications, notificationCount, setTimeAndCurrency } = userSlice.actions;

export const loginUser = (state) => state.user;

export default userSlice.reducer;