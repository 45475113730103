import React, { memo, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiMinus, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import { displayCurrencySymbol } from "../../../utils/Utils";

function CreateEditQuoteItems(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencyCode, currencySymbol, currencyStringValue } = useSelector((state) => state?.user);
    const [isEdittable, setIsEdittable] = useState(false)

    const [subTotal, setSubTotal] = useState(props?.data?.record?.sub_total || 0.00)
    const [discount, setDiscount] = useState(props?.data?.record?.discount || 0);
    const [discountType, setDiscountType] = useState(props?.data?.record?.discount_type || 'percentage'); // 'percentage' or 'flat'
    const [tax, setTax] = useState(props?.data?.record?.tax || 0);
    const [taxType, setTaxType] = useState(props?.data?.record?.tax_type || 'percentage'); // 'percentage' or 'flat'
    const [displayDiscountAmount, setDisplayDiscountAmount] = useState(props?.data?.record?.discount_value || 0.00);
    const [displayTaxAmount, setDisplayTaxAmount] = useState(props?.data?.record?.tax_value || 0.00);
    const [adjustment, setAdjustment] = useState(props?.data?.record?.adjustment_amount || 0);
    const [grandTotal, setGrandTotal] = useState(props?.data?.record?.total || 0.00);

    const [termAndCondition, setTermAndCondition] = useState(props?.data?.term || '');
    const [description, setDescription] = useState(props?.data?.description || '');


    const [finalTotalData, setFinalTotalData] = useState({
        finalSubTotal: '',
        finalDiscount: '',
        finalDiscountPer: '',
        finalDiscountType: 'percentage',
        finalTaxDiscount: '',
        finalTaxDiscountPer: '',
        finalTaxDiscountType: 'percentage',
        finalAdjustment: '',
        finalGrandTotal: ''
    });



    const [quoteItems, setQuoteItems] = useState([
        { productId: '', amount: '', listPrice: '', quantity: 1, tax: '', total: '' }
    ]);

    const arrayData = props?.data?.record?.quote_items || []

    // Map the array to match the quoteItems structure
    const mappedQuoteItems = arrayData && arrayData.length > 0 && arrayData.map(item => ({
        productId: item.product_id,
        amount: item.amount || '',
        listPrice: item.list_price || '',
        quantity: item.quantity || 1,
        tax: item.tax || '',
        total: item.total || ''
    }));

    // Set the mapped items to state
    useEffect(() => {
        setQuoteItems(mappedQuoteItems);
    }, [arrayData]);


    const handleItemChange = async (index, field, value) => {
        const newItems = [...quoteItems];
        if (field === 'product_id') {
            // Trigger API call to get product details
            try {
                const response = await axiosInstance.post('/api/quote/add-temp', { product_id: value });
                const productData = response.data.data.product;

                const price = parseFloat(productData.price || 0);
                const tax = parseFloat(productData.tax || 0);
                const quantity = newItems[index].quantity;

                // Update the item with product details
                newItems[index] = {
                    // ...newItems[index],
                    // productId: value,
                    // amount: productData.price || '',
                    // listPrice: productData.price || '',
                    // tax: productData.tax || '',
                    // total: (productData.price || 0) * newItems[index].quantity + (productData.tax || 0)
                    ...newItems[index],
                    productId: value,
                    amount: price,
                    listPrice: price,
                    tax: tax,
                    total: (price * quantity) + tax
                };
                setIsEdittable(false)
            } catch (error) {
                console.error("Failed to fetch product data:", error);
                // Handle error, maybe set an error state or show a notification
            }
        } else {
            newItems[index][field] = value;
        }

        // Recalculate total if amount or quantity changed
        if (field === 'amount' || field === 'quantity' || field === 'tax') {
            const amount = parseFloat(newItems[index].amount) || 0;
            const quantity = parseInt(newItems[index].quantity) || 1;
            const tax = parseFloat(newItems[index].tax) || 0;
            newItems[index].total = (amount * quantity) + tax;
        }

        setQuoteItems(newItems);
    };

    const addNewItem = () => {
        setQuoteItems([...quoteItems, { productId: '', amount: '', listPrice: '', quantity: 1, tax: '', total: '' }]);
    };

    const removeItem = (index) => {
        if (quoteItems.length > 1) {
            const newItems = quoteItems.filter((item, i) => i !== index);
            setQuoteItems(newItems);
        } else {
            showSnackbar('Sorry...! You can not delete all item', "warning");
        }
    };


    useEffect(() => {
        const newSubtotal = quoteItems.reduce((subtotal, item) => subtotal + (parseFloat(item.total) || 0), 0);
        setSubTotal(newSubtotal);

        // Update Formik's field value
        //props.data.setFieldValue('QuoteItemArray', quoteItems)

    }, [quoteItems]);


    // Calculate grand total whenever subtotal, discount, tax, or adjustment changes
    useEffect(() => {

        const parsedSubTotal = parseFloat(subTotal) || 0;
        const parsedDiscount = parseFloat(discount) || 0;
        const parsedTax = parseFloat(tax) || 0;
        const parsedAdjustment = parseFloat(adjustment) || 0;

        const discountAmount = discountType === 'percentage' ? (parsedSubTotal * parsedDiscount) / 100 : parsedDiscount;
        const taxAmount = taxType === 'percentage' ? (parsedSubTotal * parsedTax) / 100 : parsedTax;
        const newGrandTotal = parsedSubTotal - discountAmount + taxAmount + parsedAdjustment;

        // Calculate display values without setting state directly here
        const updatedFinalTotalData = {
            finalSubTotal: subTotal,
            finalDiscount: discount,
            finalDiscountPer: discountAmount,
            finalDiscountType: discountType,
            finalTaxDiscount: tax,
            finalTaxDiscountPer: taxAmount,
            finalTaxDiscountType: taxType,
            finalAdjustment: adjustment,
            finalGrandTotal: newGrandTotal
        };


        setGrandTotal(newGrandTotal);
        setDisplayDiscountAmount(discountAmount)
        setDisplayTaxAmount(taxAmount)
        setFinalTotalData(updatedFinalTotalData);

        // Update Formik's field value
        //props.data.setFieldValue('finalData', updatedFinalTotalData);

    }, [subTotal, discount, discountType, tax, taxType, adjustment]);

    const handleSubmitData = async () => {
        const data = {
            id: props?.data?.quoteID || '',
            editType: 'itemOnly',
            quote_item_array: quoteItems,
            finalTotalData: finalTotalData,
        }

        showLoader()
        await axiosInstance.post("api/quote/update", data).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
                props?.data?.handleEditQuotedItems(false)
                props?.data?.handleParentReload()
                hideLoader()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400 || error.response.status === 300) {
                    var splt = error?.response?.data?.message.split(' ')
                    if (splt && splt.length >= 2) {
                        showSnackbar('Please select Product on ' + splt[0] + splt[1] + 1, "warning");
                    } else {
                        showSnackbar('Oops Something went wrong..!', "warning");
                    }
                }
            }
        });
    }

    return (

        <Grid item xs={12}>
            <div className="card p-16">
                <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Quoted Items</Typography>

                <ul className="quoted-item-list">
                    {quoteItems.map((quote, index) => (
                        <li key={index}>
                            <Grid container spacing={1.5} alignItems={{ xxl: "center", xs: 'flex-start' }}>
                                <Grid item xs='auto'>
                                    <div className="serial">{index + 1}</div>
                                </Grid>
                                <Grid item xs>
                                    <Grid container spacing={1.5} alignItems={"center"}>
                                        <Grid item xxl xs={12}>
                                            <div className="control-hint">
                                                <TextField
                                                    select
                                                    variant="filled"
                                                    label='Product Interested'
                                                    value={quote?.productId || ''}
                                                    onChange={(e) => handleItemChange(index, 'product_id', e.target.value)}
                                                >
                                                    {props?.data?.product ?
                                                        props.data.product.map(product => (
                                                            <MenuItem key={product.id} value={product.id}>
                                                                {product.name}
                                                            </MenuItem>
                                                        )) :
                                                        <MenuItem key={1}></MenuItem>
                                                    }
                                                </TextField>
                                                <span className="hint">List Price: {quote.listPrice ? displayCurrencySymbol(quote.listPrice, currencyCode, currencySymbol, currencyStringValue) : 0}</span>
                                            </div>
                                        </Grid>
                                        <Grid item xxl='auto' xs={12} className="bottom-row">
                                            <Grid container spacing={1.5} alignItems={"center"}>
                                                <Grid item xs>
                                                    <TextField
                                                        variant="filled"
                                                        label={'Amount (' + currencySymbol + ')'}
                                                        value={quote.amount}
                                                        disabled={isEdittable}
                                                        onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                                                        className="fixed-width"
                                                    />
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <TextField
                                                        variant="filled"
                                                        value={quote.quantity}
                                                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                                        className="no-label text-center number-stepper"
                                                        disabled={isEdittable}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <IconButton onClick={() => {
                                                                    const newQuantity = Math.max(quote.quantity - 1, 1)
                                                                    handleItemChange(index, 'quantity', newQuantity)
                                                                }}>
                                                                    <Icon path={mdiMinus} color={'currentColor'} size={'20px'} />
                                                                </IconButton>
                                                            ),
                                                            endAdornment: (
                                                                <IconButton onClick={() => handleItemChange(index, 'quantity', quote.quantity + 1)}>
                                                                    <Icon path={mdiPlus} color={'currentColor'} size={'20px'} />
                                                                </IconButton>
                                                            ),

                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField
                                                        variant="filled"
                                                        label={'Tax (' + currencySymbol + ')'}
                                                        value={quote.tax}
                                                        disabled={isEdittable}
                                                        onChange={(e) => handleItemChange(index, 'tax', e.target.value)}
                                                        className="fixed-width"
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField
                                                        variant="filled"
                                                        label={'Total (' + currencySymbol + ')'}
                                                        value={quote.total}
                                                        disabled={isEdittable}
                                                        onChange={(e) => handleItemChange(index, 'total', e.target.value)}
                                                        className="fixed-width"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <div className="mb-24">
                                                        <Tooltip title="Delete Item">
                                                            <span>
                                                                <button type="button" className="icon-button" onClick={() => removeItem(index)}>
                                                                    <Icon path={mdiTrashCanOutline} size={1} color={'currentColor'} />
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </li>
                    ))}
                </ul>

                <Grid container spacing={1.5} justifyContent={"space-between"}>
                    <Grid item xxl={4} lg={5.5} xs={12}>
                        <Button type="button" color="secondary" size="small" onClick={addNewItem}>
                            <span>
                                <Icon path={mdiPlus} />
                                <span>Add More Items</span>
                            </span>
                        </Button>
                    </Grid>
                    <Grid item xxl={4} lg={5.5} xs={12}>
                        <Box sx={{ display: { lg: "none", xs: "block" } }}>
                            <hr className="mb-16 mt-8" />
                        </Box>

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography variant="title-medium" className="text-secondary">Subtotal</Typography>
                            <Typography variant="title-large">{subTotal ? displayCurrencySymbol(subTotal, currencyCode, currencySymbol, currencyStringValue) : currencySymbol + ' ' + 0}</Typography>
                        </Stack>
                    </Grid>
                </Grid>

                <hr className="my-16" />

                <Grid container spacing={1.5} direction={"row-reverse"} justifyContent={"space-between"}>
                    <Grid item xxl={4} lg={5.5} xs={12}>
                        <div className="control-hint">
                            <Grid container spacing={1.5}>
                                <Grid item xs>
                                    <TextField
                                        variant="filled"
                                        label='Discount'
                                        value={discount}
                                        disabled={isEdittable}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            // Allow empty input or input with just a decimal
                                            if (value === '' || value === '.' || /^[0-9]*\.?[0-9]*$/.test(value)) {
                                                setDiscount(value);
                                            }
                                        }}
                                        onBlur={() => {
                                            // Convert to float on blur or leave as 0 if invalid
                                            setDiscount(parseFloat(discount) || 0);
                                        }}
                                        onFocus={() => {
                                            // Clear the value if it's 0 on focus
                                            if (discount === 0) {
                                                setDiscount('');
                                            }
                                        }}
                                        inputProps={{ inputMode: 'decimal' }}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <TextField
                                        select
                                        variant="filled"
                                        value={discountType}
                                        disabled={isEdittable}
                                        className="no-label"
                                        onChange={(e) => setDiscountType(e.target.value)}
                                    >
                                        <MenuItem key={1} value='percentage'>Percentage</MenuItem>
                                        <MenuItem key={2} value='flat'>Flat</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <span className="hint bottom">Discount: {displayDiscountAmount ? displayCurrencySymbol(displayDiscountAmount, currencyCode, currencySymbol, currencyStringValue) : currencySymbol + ' ' + 0}</span>
                        </div>

                        <div className="control-hint">
                            <Grid container spacing={1.5}>
                                <Grid item xs>
                                    <TextField
                                        variant="filled"
                                        label='Tax'
                                        value={tax}
                                        disabled={isEdittable}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            // Allow empty input or input with just a decimal
                                            if (value === '' || value === '.' || /^[0-9]*\.?[0-9]*$/.test(value)) {
                                                setTax(value);
                                            }
                                        }}
                                        onBlur={() => {
                                            // Convert to float on blur or leave as 0 if invalid
                                            setTax(parseFloat(tax) || 0);
                                        }}
                                        onFocus={() => {
                                            // Clear the value if it's 0 on focus
                                            if (discount === 0) {
                                                setTax('');
                                            }
                                        }}
                                        inputProps={{ inputMode: 'decimal' }}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <TextField
                                        select
                                        variant="filled"
                                        value={taxType}
                                        disabled={isEdittable}
                                        onChange={(e) => setTaxType(e.target.value)}
                                        className="no-label"
                                    >
                                        <MenuItem key={1} value='percentage'>Percentage</MenuItem>
                                        <MenuItem key={2} value='flat'>Flat</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <span className="hint bottom">Tax: {displayTaxAmount ? displayCurrencySymbol(displayTaxAmount, currencyCode, currencySymbol, currencyStringValue) : currencySymbol + ' ' + 0}</span>
                        </div>

                        <TextField
                            variant="filled"
                            label={'Adjustment (' + currencySymbol + ')'}
                            value={adjustment}
                            disabled={isEdittable}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow empty input or input with just a decimal
                                if (value === '' || value === '.' || /^-?[0-9]*\.?[0-9]*$/.test(value)) {
                                    setAdjustment(value);
                                }
                            }}
                            onBlur={() => {
                                // Convert to float on blur or leave as 0 if invalid
                                setAdjustment(parseFloat(adjustment) || 0);
                            }}
                            onFocus={() => {
                                // Clear the value if it's 0 on focus
                                if (discount === 0) {
                                    setAdjustment('');
                                }
                            }}
                            inputProps={{ inputMode: 'decimal' }}
                        />

                        <hr className="mb-16" />

                        <Stack direction={"row"} alignItems={"baseline"} justifyContent={"space-between"}>
                            <Typography variant="title-large">Grand Total</Typography>
                            <Typography variant="headline-medium-semibold">{grandTotal ? displayCurrencySymbol(grandTotal, currencyCode, currencySymbol, currencyStringValue) : currencySymbol + ' ' + 0}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xxl={4} lg={5.5} xs={12}>
                        <Box sx={{ display: { lg: "none", xs: "block" } }}>
                            <hr className="mb-16" />
                        </Box>

                        <TextField
                            multiline
                            rows={3}
                            label='Terms & Conditions'
                            variant="filled"
                            value={termAndCondition}
                            name="terms_and_condition"
                            onChange={(e) => { setTermAndCondition(e.target.value) }}
                        />

                        <TextField
                            multiline
                            rows={3}
                            label='Description Information'
                            value={description}
                            variant="filled"
                            name="description"
                            onChange={(e) => { setDescription(e.target.value) }}
                        />

                    </Grid>
                </Grid>
                <Stack direction={'row'} justifyContent={"flex-end"} spacing={1}>
                    <Button onClick={e => handleSubmitData()}>Save</Button>
                    <Button color="secondary" onClick={e => { props?.data?.handleEditQuotedItems(false) }}>Cancel</Button>
                </Stack>
            </div>
        </Grid>
    )
}

export default memo(CreateEditQuoteItems);
