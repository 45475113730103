import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from "./Sidebar"
import Header from "./Header"
import { mdiHelp } from "@mdi/js";
import Icon from "@mdi/react";
import authService from '../../services/AuthService';
import useAxios from '../../hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, login, permissions, workspaces, logout, notifications, notificationCount, setTimeAndCurrency } from '../../redux/slices/userSlice';
import Cookies from "universal-cookie";
import { useSnackbar } from "../context/SnackbarContext";
import HelpPopup from "../common/helpPopup";
import { getSubdomain, replaceSubdomain } from "../../utils/Utils";

const PrivateLayout = () => {
    const location = useLocation();
    const { pathname } = location;
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const userPermissions = useSelector((state) => state.user.permission);
    const userWorkspace = useSelector((state) => state.user.workspace);
    const loginUserDetail = useSelector(loginUser);
    const user = loginUserDetail?.user || [];
    const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState(null);
    const [helpMenuOpen, setHelpMenuOpen] = useState(false);

    useEffect(() => {
        if (cookies.get('auth_token')) {
            let parts = window.location.host.split(".");
            if (parts.length === 2 && !user) {
                navigate('/sign-in')
            }

            const getSubDomain = getSubdomain(window.location.href)
            if (getSubDomain) {
                async function fetchData() {
                    await getData(getSubDomain, false);
                }
                fetchData();
            } else {
                showSnackbar('Oops! Something went wrong. The subdomain was not found.', 'error')
                dispatch(logout({ navigate }));
            }
        } else {
            // const searchParams = new URLSearchParams(window.location.search);
            // const redirectTenant = searchParams.get('redirectData'); // Encoded data
            // const redirectToken = searchParams.get('token'); // Encoded data
            // const workSpaceID = searchParams.get('wid');

            // if (redirectToken && redirectTenant && workSpaceID) {
            //     const hostname = getMainDomain()

            //     // Delete cookies before setting new values
            //     cookies.remove('auth_token', { domain: hostname || '' });
            //     cookies.remove('auth_name', { domain: hostname || '' });
            //     cookies.remove('auth_tenant', { domain: hostname || '' });
            //     cookies.remove('tenant_workspace', { domain: hostname || '' });

            //     // Set new cookie values
            //     redirectToken && cookies.set('auth_token', redirectToken, { domain: hostname || '' });
            //     cookies.set('auth_name', 'Harshiv Dodiya', { domain: hostname || '' });
            //     redirectTenant && cookies.set('auth_tenant', redirectTenant, { domain: hostname || '' });
            //     workSpaceID && cookies.set('tenant_workspace', workSpaceID, { domain: hostname || '' });
            //     const subDomain = getSubdomain(window.location.href)

            //     getData(subDomain, true);
            // } else {
            //     dispatch(logout({ navigate }));
            // }
            dispatch(logout({ navigate })); //Original
        }
    }, [pathname]);

    if (!authService.isAuthenticated()) { //Original
        return <Navigate to="/sign-in" />;
    }

    //====Start:Set new session data for user click on new tab on same browser====//
    async function getData(subdomain, redirectTo) {
        await axiosInstance.get("/api/user/getLoginUser?sdomain=" + subdomain).then(response => {
            if (response.status === 200) {
                const getSubdomain = response?.data?.data?.currentTenant?.domain || '';

                dispatch(
                    notifications(response.data.data.notifications),
                );

                dispatch(
                    notificationCount(response.data.data.notifications.length),
                );

                if (response?.data?.data?.domainAccess === 'no') {
                    showSnackbar('Oops! You do not have permission to access this workspace.', 'warning')
                    if (!getSubdomain) {
                        subdomain = 'app'
                    }

                    const newSubDomainUrl = replaceSubdomain(window.location.href, subdomain)
                    if (newSubDomainUrl) {
                        window.location = newSubDomainUrl;
                    }
                } else {
                    if (response?.data?.data?.domainAccess === 'yes') {
                        if (response?.data?.data?.user) {
                            dispatch(
                                login(response?.data?.data?.user || []),
                            );
                            dispatch(setTimeAndCurrency({
                                preferredDateFormat: response?.data?.data?.staticData?.preDateFormat || 'YYYY-MM-DD HH:mm',
                                preTimeZoneKey: response?.data?.data?.staticData?.preTimeZoneKey || 'Asia/Calcutta',
                                currencySymbol: response?.data?.data?.staticData?.preCurrency?.currencySymbol || '₹',
                                currencyCode: response?.data?.data?.staticData?.preCurrency?.currencyCode || 'INR',
                                currencyStringValue: response?.data?.data?.staticData?.preCurrency?.currencyStringValue || 'en-IN'
                            }));
                        } else {
                            dispatch(
                                login([]),
                            );
                        }

                        if (response?.data?.data?.user?.packageType !== 'offline') {
                            if (pathname !== '/checkout' && response?.data?.data?.user?.subRemainingDays === -1) {
                                if (!getSubdomain) {
                                    subdomain = 'app'
                                }
                                const newSubDomainUrl = replaceSubdomain(window.location.href, subdomain)
                                if (newSubDomainUrl) {
                                    try {
                                        // Create a URL object for easier manipulation
                                        const parsedUrl = new URL(newSubDomainUrl);

                                        // Split the pathname to identify the current page and replace it
                                        const pathSegments = parsedUrl.pathname.split('/');

                                        // Replace the last segment (current page) with the new page name
                                        pathSegments[pathSegments.length - 1] = 'checkout';

                                        // Update the pathname in the URL object
                                        parsedUrl.pathname = pathSegments.join('/');

                                        // Return the modified URL as a string
                                        window.location = parsedUrl.toString();
                                    } catch (error) {
                                        showSnackbar('Your subscription will ended, please contact you admin to access workspace.', 'error');
                                        dispatch(logout({ navigate }));
                                    }
                                }
                            }
                        }

                        //check if tenant subscription is over 
                        const userData = response?.data?.data?.user;
                        if (userData?.type === 3 && userData?.subRemainingDays === '-1' && !['Admin', 'SuperAdmin'].includes(userData?.role)) {
                            showSnackbar('Your subscription will ended, please contact you admin to access workspace.', 'warning');
                            setTimeout(() => {
                                dispatch(logout({ navigate }));
                            }, 2000);
                        }

                        if (response?.data?.data?.currentTenant) {
                            dispatch(
                                workspaces(response?.data?.data?.currentTenant),
                            );
                        } else {
                            dispatch(
                                workspaces([]),
                            );
                        }

                        if (response?.data?.data?.permissionArray) {
                            dispatch(
                                permissions(response?.data?.data?.permissionArray)
                            );
                        } else {
                            dispatch(
                                permissions([])
                            );
                        }
                    }
                }

                if (redirectTo) {
                    navigate('/dashboard')
                }
            }
        }).then(json => {
            showSnackbar(json.data.data.message, 'warning');
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    dispatch(logout({ navigate }));
                }
            }
        });
    }
    //====End:Set new session data for user click on new tab on same browser====//

    // Map paths to titles
    const getTitle = (pathname) => {
        if (pathname === '/dashboard') {
            return 'Dashboard';
        } else if (pathname === '/leads') {
            return 'Leads';
        } else if (/^\/lead-detail\/\d+$/.test(pathname)) {
            return 'Lead Detail';
        } else if (pathname === '/deals') {
            return 'Deals';
        } else if (/^\/deal-detail\/\d+$/.test(pathname)) {
            return 'Deal Detail';
        } else if (pathname === '/quotes') {
            return 'Quotes';
        } else if (/^\/quote-detail\/\d+$/.test(pathname)) {
            return 'Quote Detail';
        } else if (/^\/quote-clone\/\d+$/.test(pathname)) {
            return 'Quote Clone';
        } else if (pathname === '/create-quote') {
            return 'Create Quote';
        } else if (pathname === '/accounts') {
            return 'Accounts';
        } else if (/^\/account-detail\/\d+$/.test(pathname)) {
            return 'Account Detail';
        } else if (pathname === '/tasks') {
            return 'Tasks';
        } else if (pathname === '/events') {
            return 'Events';
        } else if (pathname === '/contacts') {
            return 'Contacts';
        } else if (/^\/contact-detail\/\d+$/.test(pathname)) {
            return 'Contact Detail';
        } else if (pathname === '/reports') {
            return 'Reports';
        } else if (pathname === '/reports/track-users') {
            return 'Track Users';
        } else if (pathname === '/reports/visit-log-activities') {
            return 'Visit Log Activities';
        } else if (pathname === '/settings') {
            return 'Settings';
        } else if (pathname === '/settings/users') {
            return 'Users';
        } else if (/^\/settings\/user-detail\/\d+$/.test(pathname)) {
            return 'User Detail';
        } else if (pathname === '/settings/roles') {
            return 'Roles';
        } else if (/^\/settings\/role-detail\/\d+$/.test(pathname)) {
            return 'Role Detail';
        } else if (pathname === '/profile') {
            return 'Profile';
        } else if (pathname === '/settings/products') {
            return 'Products';
        } else if (/^\/settings\/product-detail\/\d+$/.test(pathname)) {
            return 'Product Detail';
        } else if (pathname === '/settings/stages') {
            return 'Stages';
        } else if (pathname === '/settings/sources') {
            return 'Sources';
        } else if (pathname === '/settings/activity-logs') {
            return 'Activity Logs';
        } else if (pathname === '/checkout') {
            return 'Checkout';
        } else {
            return '';
        }
    };

    const handleHelpMenuClick = (event) => {
        setHelpMenuOpen(true);
        setHelpMenuAnchorEl(event.currentTarget);
        document.body.classList.add('show-popover-backdrop');
    };

    const handleHelpMenuClose = () => {
        setHelpMenuOpen(false);
        setHelpMenuAnchorEl(null);
        document.body.classList.remove('show-popover-backdrop');
    };

    return (
        <>
            <main className="main-layout">
                <Sidebar userPermissions={userPermissions} userWorkspace={userWorkspace} />
                <section className="main-content">
                    <Header pageTitle={getTitle(pathname)} />
                    <Outlet />
                </section>
            </main>

            <button type="button" className="icon-button size-medium floating-action" onClick={handleHelpMenuClick}>
                <Icon path={mdiHelp} color={'currentColor'} size={1} />
            </button>

            <HelpPopup helpMenuOpen={helpMenuOpen} helpMenuAnchorEl={helpMenuAnchorEl} handleHelpMenuClose={handleHelpMenuClose} />


        </>
    );
};

export default PrivateLayout;