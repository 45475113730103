import { mdiCalendarCheckOutline, mdiEmailOutline, mdiHistory, mdiPencilOutline, mdiPercentBoxOutline, mdiPhoneOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import CreateDealForExistingAccountDialog from "../../deal/components/CreateDealForExistingAccountDialog";
import EmptyState from "../../../components/common/EmptyState";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayCurrencySymbol } from "../../../utils/Utils";

function AccountDealList({ account_id, handleReloadParentFunc, userPermissions }) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencySymbol, currencyCode, currencyStringValue } = useSelector((state) => state?.user);

    //default data for list api
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [reloadPage, setReloadPage] = useState(false);
    const [deals, setDeals] = useState();
    const [leadSourceList, setLeadSourceList] = useState([]);

    useEffect(() => {
        if (account_id) {
            async function fetchData() {
                showLoader();
                await getDealData();
            }
            fetchData();
        }
    }, [account_id, reloadPage]);

    const getDealData = async (currentPage, perPage) => {
        const params = {
            page: currentPage + 1,
            per_page: perPage,
            account_id: account_id,
        };

        await axiosInstance.get(`api/deal`, { params }).then((response) => {
            if (response.status === 200) {
                setDeals(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setLeadSourceList(response?.data?.data?.leadSources || []);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/accounts");
                    }
                }
            });
    };

    const handleReloadFunc = () => {
        setReloadPage(reloadPage ? false : true);
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    //=======Start::Write a code for delete deal contact=======//
    async function deleteData(id) {
        const data = {
            id: id,
            account_id: account_id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/deal/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    handleReloadParentFunc()
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete deal contact=======//

    const [createNewDealDialogOpen, setCreateNewDealDialogOpen] = React.useState(false);
    const openCreateNewDealDialog = () => {
        setCreateNewDealDialogOpen(true);
    }
    const closeCreateNewDealDialog = () => {
        setCreateNewDealDialogOpen(false);
    }

    return (
        <>
            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Deals</h6>
                    {userPermissions && userPermissions.includes("deal_create") &&
                        <Button color="secondary" size="small" className="action" onClick={openCreateNewDealDialog}>
                            <span>
                                <Icon path={mdiPlus} color={'currentColor'} size={1} />
                                <span>Add</span>
                            </span>
                        </Button>
                    }
                </div>
                {deals && deals.length > 0 && (
                    <ul className="primary-list">
                        {deals.map((deal, index) => (
                            <li key={index + ''}>
                                <Link to={userPermissions?.includes("deal_edit") ? '/deal-detail/' + deal?.id : '#'} className="card">
                                    <Typography variant='title-medium-bold' component={'h6'}>{capitalize(deal?.deal_name || 'N/A')}</Typography>
                                    <ul className='inline-details'>
                                        <li><span className="bold">{deal?.deal_amount ? displayCurrencySymbol(deal?.deal_amount, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</span></li>
                                        <li>
                                            <Tooltip title="Stage">
                                                <span>
                                                    {deal?.get_stage?.name === 'New' && <span className="status-badge">{deal?.selected_stage_name?.name || 'N/A'}</span>}
                                                    {deal?.get_stage?.name === 'Open' && <span className="status-badge warning">{deal?.selected_stage_name?.name || 'N/A'}</span>}
                                                    {deal?.get_stage?.name === 'Closed Won' && <span className="status-badge success">{deal?.selected_stage_name?.name || 'N/A'}</span>}
                                                    {deal?.get_stage?.name === 'Closed Lost' && <span className="status-badge danger">{deal?.selected_stage_name?.name || 'N/A'}</span>}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Probability">
                                                <span>
                                                    <Icon path={mdiPercentBoxOutline} color="currentColor" size={1} />{deal?.deal_probability || 'N/A'}%
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Expected Closing Date">
                                                <span>
                                                    <Icon path={mdiCalendarCheckOutline} color="currentColor" size={1} />
                                                    {
                                                        deal?.expected_closing_date
                                                            ? moment.utc(deal?.expected_closing_date)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                    <ul className='inline-details other-info ml-0'>
                                        <li><span className='title'>Closing Date:</span>
                                            {
                                                deal?.closing_date
                                                    ? moment.utc(deal?.closing_date)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                        </li>
                                        <li>
                                            <Tooltip title="Created Date">
                                                <span>
                                                    <Icon path={mdiHistory} color="currentColor" size={1} />
                                                    {
                                                        deal?.created_at
                                                            ? moment.utc(deal?.created_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </Link>
                                <Stack direction={'row'} className='actions'>
                                    {userPermissions && userPermissions.includes("deal_edit") &&
                                        <Tooltip title="Edit">
                                            <span>
                                                <Link to={'/deal-detail/' + deal?.id} className='icon-button'>
                                                    <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                </Link>
                                            </span>
                                        </Tooltip>
                                    }
                                    {userPermissions && userPermissions.includes("deal_delete") &&
                                        <Tooltip title="Delete">
                                            <button type="button" className='icon-button' onClick={() => {
                                                deleteData(deal?.id)
                                            }}>
                                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                            </button>
                                        </Tooltip>
                                    }
                                </Stack>
                            </li>
                        ))
                        }
                    </ul>
                )}
                {(!deals || deals.length === 0) && !loading &&
                    <EmptyState type={'deal'} hideActionBtn />
                }

                {deals && deals.length > 0 &&
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                }
            </div>

            {
                createNewDealDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CreateDealForExistingAccountDialog
                            open={createNewDealDialogOpen}
                            onClose={closeCreateNewDealDialog}
                            sources={leadSourceList}
                            handleReloadFunc={handleReloadFunc}
                            accountId={account_id}
                        />
                    </Suspense>
                )}
        </>
    );
}

export default AccountDealList;