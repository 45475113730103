import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, FormControlLabel, Grid, Switch, TextField, Tooltip } from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSelector } from "react-redux";
import { displayDate } from "../../utils/Utils";


function AddEventDialog({ onClose, open, type, dataId, handleReloadFunc, editId }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [eventList, setEventList] = useState([])
    const [isAllDay, setIsAllDay] = useState(false)
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);

    //=======Start::Write a code for manage Edit Notes======//
    useEffect(() => {
        if (editId) {
            const fetchData = async () => {
                await getEvent();
            };
            fetchData();
        }
    }, [editId]);

    async function getEvent() {
        showLoader()
        try {
            const response = await axiosInstance.get(`api/${type}-event/edit/${editId}?${type}Id=${dataId}`);
            if (response.data.code === 200) {
                setEventList(response?.data?.data?.record);
                setIsAllDay(response?.data?.data?.record?.is_fullday ? true : false)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        } finally {
            hideLoader();
        }
    }
    //=======Start::Write a code for manage Edit Notes======//


    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required("Enter event subject")
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters."),
        description: yup
            .string()
            .required("Enter event description")
            .min(2, "The description must be at least 2 characters.")
            .max(150, "The description must not be greater than 150 characters."),
        start_date: yup
            .string()
            .required("Enter start date"),
        end_date: yup
            .string()
            .required("Enter end date"),
    });


    const handleAllDay = () => {
        setIsAllDay(prevIsAllDay => !prevIsAllDay);
    };

    // useEffect(() => {

    // }, [isAllDay]);


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            id: eventList?.id,
            leadId: dataId,
            dealId: dataId,
            name: values.name,
            description: values.description,
            allDay: isAllDay ? "yes" : "no",
            start_date: values?.start_date ? isAllDay ? moment(values.start_date).format("YYYY-MM-DD") : moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : null,
            end_date: values.end_date ? isAllDay ? moment(values.end_date).format("YYYY-MM-DD") : moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : null,
            location: values.location,
        };

        await axiosInstance.post(`/api/${type}-event/${editId ? 'update' : 'store'}`, data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });

                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>{editId ? 'Edit' : 'Add'} Event</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        name: eventList?.name || '',
                        description: eventList?.description || '',
                        start_date: eventList?.start_date ? moment(eventList?.start_date) : moment(),
                        end_date: eventList?.end_date ? moment(eventList?.end_date) : moment().add(1, 'days'),
                        location: eventList?.location,
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={editId ? true : false}
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Subject"
                                    variant="filled"
                                    name="name"
                                    value={values?.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />

                                <TextField
                                    label="Description"
                                    variant="filled"
                                    name="description"
                                    value={values?.description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    multiline
                                    rows={3}
                                    error={errors.description && touched.description}
                                    helperText={(errors.description && touched.description) && errors.description}
                                />

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    {
                                        isAllDay ?
                                            <DatePicker
                                                label="Start Date"
                                                format={displayDate(preferredDateFormat, 'half')}
                                                views={['year', 'month', 'day']}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'filled',
                                                        name: "start_date",
                                                        type: 'text',
                                                        error: errors.start_date && touched.start_date,
                                                        helperText: (errors.start_date && touched.start_date) && errors.start_date
                                                    }
                                                }}
                                                name="start_date"
                                                value={values?.start_date}
                                                onChange={(value) => setFieldValue("start_date", value, true)}
                                                onBlur={handleBlur}
                                            />
                                            :
                                            <DateTimePicker
                                                label="Start Date"
                                                format={preferredDateFormat}
                                                views={["day", "hours", "minutes", "month", "year"]}
                                                name="start_date"
                                                slotProps={{
                                                    textField: {
                                                        variant: 'filled',
                                                        name: "start_date",
                                                        type: 'text',
                                                        error: errors.start_date && touched.start_date,
                                                        helperText: (errors.start_date && touched.start_date) && errors.start_date
                                                    }
                                                }}
                                                value={values?.start_date ? moment.utc(values.start_date).tz(preTimeZoneKey) : moment()}
                                                onChange={(value) => setFieldValue("start_date", value, true)}
                                                onBlur={handleBlur}
                                            />
                                    }

                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    {
                                        isAllDay ?
                                            <DatePicker
                                                label="End Date"
                                                format={displayDate(preferredDateFormat, 'half')}
                                                views={['year', 'month', 'day']}
                                                name="end_date"
                                                slotProps={{
                                                    textField: {
                                                        variant: 'filled',
                                                        name: "end_date",
                                                        type: 'text',
                                                        error: errors.end_date && touched.end_date,
                                                        helperText: (errors.end_date && touched.end_date) && errors.end_date
                                                    }
                                                }}
                                                value={values?.end_date ? moment.utc(values.end_date).tz(preTimeZoneKey) : moment()}
                                                onChange={(value) => setFieldValue("end_date", value, true)}
                                                onBlur={handleBlur}

                                            /> :
                                            <DateTimePicker
                                                label="End Date"
                                                format={preferredDateFormat}
                                                views={["day", "hours", "minutes", "month", "year"]}
                                                name="end_date"
                                                slotProps={{
                                                    textField: {
                                                        variant: 'filled',
                                                        name: "end_date",
                                                        type: 'text',
                                                        error: errors.end_date && touched.end_date,
                                                        helperText: (errors.end_date && touched.end_date) && errors.end_date
                                                    }
                                                }}
                                                value={values?.end_date ? moment.utc(values.end_date).tz(preTimeZoneKey) : moment()}
                                                onChange={(value) => setFieldValue("end_date", value, true)}
                                                onBlur={handleBlur}
                                            />
                                    }

                                </LocalizationProvider>

                                <div className="form-group">
                                    <FormControlLabel control={<Switch onChange={() => handleAllDay(isAllDay)} checked={isAllDay} />} label="All Day Event" />
                                </div>

                                <TextField
                                    label="Location"
                                    variant="filled"
                                    name="location"
                                    value={values?.location || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.location && touched.location}
                                    helperText={(errors.location && touched.location) && errors.location}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button className="btn-block" type="submit">{editId ? 'Edit' : 'Add'} Event</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog >
        </>
    );
}

export default AddEventDialog;