import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Dialog, FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomTextbox from "../../../components/common/EasyEditCustomTextbox";
import FormGroupStatic from "../../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../../components/common/EasyEditButtons";
import EasyEditDateTimePicker from "../../../components/common/EasyEditDateTimePicker";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { displayDate, validateField } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useLoader } from "../../../components/context/LoaderContext";
import { useNavigate } from "react-router-dom";

function EventDetailsDialog({ onClose, open, eventID, handleReloadFunc }) {
    const navigate = useNavigate();
    const { hideLoader, showLoader, loading } = useLoader();
    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [event, setEvent] = useState(null);
    const [listData, setListData] = useState(null);
    //const [isAllDay, setIsAllDay] = useState(false);
    const [ErrorInput, setErrorInput] = useState({ error_list: [] });
    const [isAllDayState, setIsAllDayState] = useState(false);


    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [eventID]);

    const getData = async () => {
        showLoader()
        await axiosInstance.get('api/activity/edit/' + eventID).then((response) => {
            if (response.status === 200) {
                setEvent(response.data?.data?.record);
                setListData(response?.data?.data);
                //setIsAllDay(response?.data?.data?.record?.is_fullday ? true : false)
                setIsAllDayState(response?.data?.data?.record?.is_fullday ? true : false)
                hideLoader();
                setErrorMessages({})
            }
        }).catch((error) => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                    navigate("/events");
                }
            }
        });
    }


    // useEffect(() => {
    //     setIsAllDayState(isAllDayState);
    // }, [isAllDayState]);


    const handleSave = async (value, name, isfull = null) => {

        setBackendErrorMessages({})
        var startDate = event?.start_date;
        var endDate = event?.end_date;
        var start_dates = ''
        var end_dates = ''

        if (name === 'start_date') {
            startDate = value;
        } else if (name === 'end_date') {
            endDate = value;
        }


        start_dates = moment.utc(startDate).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat))
        end_dates = moment.utc(endDate).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat))

        if (isfull && !isAllDayState) {
            start_dates = moment.utc(startDate).tz(preTimeZoneKey).format(displayDate(preferredDateFormat, 'half'))
            end_dates = moment.utc(endDate).tz(preTimeZoneKey).format(displayDate(preferredDateFormat, 'half'))
        } else if ((isfull != null && !isfull) && isAllDayState) {
            start_dates = moment.utc(startDate).tz(preTimeZoneKey).format(displayDate(preferredDateFormat))
            end_dates = moment.utc(endDate).tz(preTimeZoneKey).format(displayDate(preferredDateFormat))
        }

        // var startDate = event?.start_date;;
        // var endDate = event?.end_date;;
        // var start_dates = ''
        // var end_dates = ''

        // if (name === 'start_date') {
        //     startDate = value;
        // } else if (name === 'end_date') {
        //     endDate = value;
        // }

        // if (name === 'start_date' || name === 'end_date') {
        //     start_dates = isAllDayState ? moment.utc(startDate).tz(preTimeZoneKey).format("YYYY-MM-DD") : moment.utc(startDate).tz(preTimeZoneKey).format("YYYY-MM-DD HH:mm:ss")
        //     end_dates = isAllDayState ? moment.utc(endDate).tz(preTimeZoneKey).format("YYYY-MM-DD") : moment.utc(endDate).tz(preTimeZoneKey).format("YYYY-MM-DD HH:mm:ss")
        // } else {
        //     start_dates = isAllDayState ? moment(startDate).format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD HH:mm:ss")
        //     end_dates = isAllDayState ? moment(endDate).format("YYYY-MM-DD") : moment(endDate).format("YYYY-MM-DD HH:mm:ss")
        // }

        // if (isfull === true) {
        //     start_dates = moment.utc(new Date()).tz(preTimeZoneKey).format("YYYY-MM-DD")
        //     end_dates = moment.utc(new Date()).tz(preTimeZoneKey).format("YYYY-MM-DD")
        // } else if (isfull === false) {
        //     start_dates = moment.utc(new Date(new Date(new Date()).setMinutes(new Date().getMinutes() + 15))).tz(preTimeZoneKey).format("YYYY-MM-DD HH:mm:ss")
        //     end_dates = moment.utc(new Date(new Date(new Date()).setHours(new Date().getHours() + 2))).tz(preTimeZoneKey).format("YYYY-MM-DD HH:mm:ss")
        // }

        const data = {
            id: eventID,
            name: event?.name,
            description: event?.description,
            location: event?.location,
            type: event?.lead_id ? 'lead' : 'deal',
            leadId: event?.lead_id ? event?.lead_id : '',
            dealId: event?.deal_id ? event?.deal_id : '',
            allDay: isAllDayState ? 'yes' : 'no',
            start_date: start_dates,
            end_date: end_dates,
            [name]: value,
        }

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(event, name);

        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/activity/update", data).then(response => {
                if (response.status === 200) {
                    hideLoader()
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(true)
                    handleReloadFunc();
                    getData()
                    setErrorInput({ ...ErrorInput, error_list: '' });
                }
            }).then(json => {

            }).catch(error => {
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    setAllowEditInline(true)
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        setBackendErrorMessages({ [key]: errors[key] });
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            });
        } else {
            setAllowEditInline(true)
        }

    }


    const handleSwitchChange = (event) => {
        setIsAllDayState(event.target.checked);
        handleSave(event.target.checked ? 'yes' : 'no', 'allDay', event.target.checked)
        setAllowEditInline(true);
    };

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(true);
        setBackendErrorMessages({ [fieldName]: '' })
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Event Details</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                {/* {!loading && */}
                <>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <EasyEdit
                                type={Types.TEXT}
                                onSave={(value) => { handleSave(value, 'name') }}
                                onCancel={() => { onCancelEdit('name') }}
                                value={event?.name || "N/A"}
                                allowEdit={allowEditInline}
                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Subject" value={event?.name} errorMessages={errorMessages['name']} />}
                                displayComponent={<FormGroupStatic easyEdit label="Subject" value={event?.name} />}
                                onValidate={(value) => handleValidation(value, 'name', 'Subject')}
                                validationMessage={errorMessages['name']}
                                saveButtonLabel={<EasyEditSaveButton />}
                                cancelButtonLabel={<EasyEditCancelButton />}
                                onFocus={() => handleClick('')}
                            />
                            {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                        </Grid>

                        <Grid item xs={6}>
                            <EasyEdit
                                type={Types.TEXT}
                                onSave={(value) => { handleSave(value, 'start_date') }}
                                onCancel={() => { onCancelEdit('start_date') }}
                                format={isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)}
                                value={
                                    event?.start_date ? moment.utc(event?.start_date).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)) : null
                                }
                                allowEdit={allowEditInline}
                                editComponent={
                                    <EasyEditDateTimePicker
                                        setAllowEditInline={setAllowEditInline}
                                        format={isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)}
                                        label="Start Date"
                                        value={event?.start_date ? moment.utc(event?.start_date).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)) : null}
                                        errorMessages={errorMessages['start_date']}
                                        preferredDateFormat={preferredDateFormat}
                                        isAllDay={!isAllDayState}
                                        setParentValue={(value) => {
                                            handleSave(value, 'start_date');
                                        }}
                                    />}
                                displayComponent={<FormGroupStatic easyEdit label="Start Date" value={event?.start_date ? moment.utc(event?.start_date).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)) : null} preTimeZoneKey={preTimeZoneKey} preferredDateFormat={preferredDateFormat} />}
                                saveButtonLabel={<EasyEditSaveButton />}
                                cancelButtonLabel={<EasyEditCancelButton />}
                                onFocus={() => handleClick('')}
                            />
                            {backendErrorMessages['start_date'] && <span className="easy-edit-validation-error">{backendErrorMessages['start_date']}</span>}
                        </Grid>

                        <Grid item xs={6}>
                            <EasyEdit
                                type={Types.TEXT}
                                onSave={(value) => { handleSave(value, 'end_date') }}
                                onCancel={() => { onCancelEdit('end_date') }}
                                value={
                                    event?.end_date ? moment.utc(event?.end_date).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)) : null
                                }
                                allowEdit={allowEditInline}
                                editComponent={
                                    <EasyEditDateTimePicker
                                        setAllowEditInline={setAllowEditInline}
                                        label="End Date"
                                        value={event?.end_date ? moment.utc(event?.end_date).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)) : null}
                                        errorMessages={errorMessages['end_date']}
                                        preferredDateFormat={preferredDateFormat}
                                        isAllDay={!isAllDayState}
                                        setParentValue={(value) => {
                                            handleSave(value, 'end_date');
                                        }}
                                    />}
                                displayComponent={<FormGroupStatic easyEdit label="End Date" value={event?.start_date ? moment.utc(event?.start_date).tz(preTimeZoneKey).format(isAllDayState ? displayDate(preferredDateFormat, 'half') : displayDate(preferredDateFormat)) : null} preTimeZoneKey={preTimeZoneKey} preferredDateFormat={preferredDateFormat} />}
                                saveButtonLabel={<EasyEditSaveButton />}
                                cancelButtonLabel={<EasyEditCancelButton />}
                                onFocus={() => handleClick('')}
                            />
                            {backendErrorMessages['end_date'] && <span className="easy-edit-validation-error">{backendErrorMessages['end_date']}</span>}
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch checked={isAllDayState} onChange={handleSwitchChange} />}
                                label="All Day Event"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EasyEdit
                                type={Types.TEXT}
                                onSave={(value) => { handleSave(value, 'location') }}
                                onCancel={() => { onCancelEdit('location') }}
                                value={event?.location || "N/A"}
                                allowEdit={allowEditInline}
                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Location" value={event?.location} errorMessages={errorMessages['location']} />}
                                displayComponent={<FormGroupStatic easyEdit label="Location" value={event?.location || 'N/A'} />}
                                onValidate={(value) => handleValidation(value, 'location', 'Location')}
                                validationMessage={errorMessages['location']}
                                saveButtonLabel={<EasyEditSaveButton />}
                                cancelButtonLabel={<EasyEditCancelButton />}
                                onFocus={() => handleClick('')}
                            />
                            {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['location']}</span>}
                        </Grid>

                        <Grid item xs={12}>
                            <EasyEdit
                                type={Types.TEXT}
                                onSave={(value) => { handleSave(value, 'description') }}
                                onCancel={() => { onCancelEdit('description') }}
                                value={event?.description || "N/A"}
                                allowEdit={allowEditInline}
                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description" value={event?.description} errorMessages={errorMessages['description']} />}
                                displayComponent={<FormGroupStatic easyEdit label="Description" value={event?.description || 'N/A'} />}
                                onValidate={(value) => handleValidation(value, 'description', 'Description')}
                                validationMessage={errorMessages['description']}
                                saveButtonLabel={<EasyEditSaveButton />}
                                cancelButtonLabel={<EasyEditCancelButton />}
                                onFocus={() => handleClick('')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormGroupStatic
                                easyEdit
                                label="Related to"
                                value={
                                    listData?.relatedDisplayName && listData.relatedDisplayName.name ? (
                                        listData.relatedDisplayName.type === 'Lead' ? (
                                            <a href={`/lead-detail/${listData.relatedDisplayName.id}`}>
                                                {listData.relatedDisplayName.name}
                                            </a>
                                        ) : listData.relatedDisplayName.type === 'Deal' ? (
                                            <a href={`/deal-detail/${listData.relatedDisplayName.id}`}>
                                                {listData.relatedDisplayName.name}
                                            </a>
                                        ) : (
                                            listData.relatedDisplayName.name
                                        )
                                    ) : 'N/A'
                                }
                            />
                        </Grid>
                    </Grid>
                </>
                {/* } */}
            </Dialog>
        </>
    )
}

export default EventDetailsDialog;
