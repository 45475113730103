import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, capitalize, Checkbox, Dialog, FormControlLabel, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";

function CreateNewRoleDialog({ onClose, open, handleReloadFunc }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const [permissionArray, setPermissionArray] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectPermissionArray, setSelectPermissionArray] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [isCheckAllBoxCheck, setIsCheckAllBoxCheck] = useState(false);


    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader()
        await axiosInstance.get(`api/role/create`).then((response) => {
            if (response.status === 200) {
                setPermissionArray(response?.data?.data?.permissionArray);
                setPermissions(response?.data?.data?.permissions);
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };


    const validationSchema = yup.object().shape({
        name: yup.string().required('Enter role name').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const formData = new FormData();
        formData.append("name", values.name);
        for (var i = 0; i < selectPermissionArray.length; i++) {
            formData.append("permissions[]", selectPermissionArray[i]);
        }
        showLoader()
        await axiosInstance.post("/api/role/store", formData).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    const selectAll = async (e) => {
        setIsSelectAll(e.target.checked ? true : false);
        setIsCheckAllBoxCheck(e.target.checked ? true : false);
        if (isCheckAllBoxCheck) {
            setSelectPermissionArray([]);
        } else {
            setSelectPermissionArray(permissions.map((li) => li.id));
        }
    }

    const handlePermissionCheckbox = (e, id) => {
        if (e.target.checked) {
            setSelectPermissionArray((selectPermissionArray) => [...selectPermissionArray, id]);
        } else {
            setSelectPermissionArray(selectPermissionArray.filter((x) => x !== id));
            setIsSelectAll(false);
        }
    };

    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Create New Role</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        name: '',
                        permissions: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Role Name"
                                    variant="filled"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />

                                <Stack direction="row" justifyContent={'space-between'} alignItems={"center"}>
                                    <Typography variant="body-large-semibold" component={'p'}>Permissions</Typography>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Select All"
                                        className="m-0"
                                        checked={isSelectAll}
                                        value={isSelectAll ? false : true}
                                        onClick={(e) => selectAll(e)}
                                    />
                                </Stack>

                                <TableContainer className="mb-24">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="center">Create</TableCell>
                                                <TableCell align="center">Delete</TableCell>
                                                <TableCell align="center">Edit</TableCell>
                                                <TableCell align="center">List</TableCell>
                                                <TableCell align="center">View</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                Object.entries(permissionArray).map(([key, value]) => {
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell>{capitalize(key)}</TableCell>
                                                            {value.map(function (keys) {
                                                                return (
                                                                    <TableCell align="center">
                                                                        <Checkbox
                                                                            className='grid-checkbox'
                                                                            key={keys.id}
                                                                            name="permissions[]"
                                                                            checked={selectPermissionArray.includes(keys.id)}
                                                                            onChange={(e) => {
                                                                                handlePermissionCheckbox(e, keys.id);
                                                                            }}
                                                                            value={keys.id}
                                                                        />
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                    <Grid item xs={6}>
                                        <Button type="submit" className="btn-block">Create</Button>
                                    </Grid>
                                    <Button color="secondary" onClick={onClose}>Cancel</Button>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateNewRoleDialog;