import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCalendarWeekBeginOutline, mdiEmailOutline, mdiPhoneOutline, mdiTrashCanOutline } from "@mdi/js";
import EasyEdit, { Types } from "react-easy-edit";
import FormGroupStatic from '../../components/common/FormGroupStatic';
import EasyEditCustomTextbox from '../../components/common/EasyEditCustomTextbox';
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../components/context/LoaderContext";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig, validateField } from "../../utils/Utils";
import DealContactList from "../account/components/DealContactList";
import AccountDealList from "./components/AccountDealList";

function AccountDetails(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const account_id = id
    const { showLoader, hideLoader } = useLoader();
    const axiosInstance = useAxios();
    const [accountData, setAccountData] = useState();
    const [userData, setUserData] = useState();
    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const { showSnackbar } = useSnackbar();
    const userPermissions = useSelector((state) => state.user.permission);

    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [reloadPage, setReloadPage] = useState(false);
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);


    useEffect(() => {
        async function fetchData() {
            showLoader();
            getData();
        }
        fetchData();
    }, [reloadPage]);



    const getData = async () => {
        await axiosInstance.get('api/account/edit/' + account_id).then((response) => {
            if (response.status === 200) {
                setAccountData(response?.data?.data?.record);
                setUserData(response?.data?.data?.userData)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/contacts");
                    }
                }
            });
    }


    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }


    const handleSaveData = async (value, name, type = null) => {
        const formData = new FormData();
        formData.append('id', account_id)
        formData.append('organization_name', accountData?.organization_name)

        if (type === 'file') {
            for (var i = 0; i < value.length; i++) {
                formData.append(name + '[]', value[i])
            }
        } else if (type === 'select') {
            for (var j = 0; j < value.length; j++) {
                formData.append(name + '[]', value[j])
            }
        } else {
            formData.append([name], value)
        }

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(accountData, name);

        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/account/update", formData).then(response => {
                if (response.status === 200) {
                    setReloadPage(reloadPage ? false : true)
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(allowEditInline ? false : true)
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        } else {
            setReloadPage(reloadPage ? false : true)
            setAllowEditInline(allowEditInline ? false : true)
        }
    }

    async function deleteAccountData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/account/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/contacts");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }


    return (
        <>
            <Helmet>
                <title>Account Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item xs={'auto'} className="flex-shrink-1">
                                <Typography variant='title-medium-bold' component={'h6'}>{accountData?.organization_name}</Typography>
                                <ul className='inline-details'>
                                    <li>
                                        <Tooltip title="Phone">
                                            <span>
                                                <Icon path={mdiPhoneOutline} color="currentColor" size={1} />
                                                <div onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    window.open('tel:' + accountData?.organization_phone1 || 'N/A');
                                                }}>{accountData?.organization_phone1 || 'N/A'}</div>
                                            </span>
                                        </Tooltip>
                                    </li>
                                    <li>
                                        <Tooltip title="Email">
                                            <span>
                                                <Icon path={mdiEmailOutline} color="currentColor" size={1} />
                                                <div onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    window.open('mailto:' + accountData?.organization_email || 'N/A');
                                                }}>{accountData?.organization_email || 'N/A'}</div>
                                            </span>
                                        </Tooltip>
                                    </li>
                                    <li>
                                        <Tooltip title="Created Date">
                                            <span>
                                                <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                {accountData?.created_at
                                                    ? moment.utc(accountData?.created_at)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                            </span>
                                        </Tooltip>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                {userPermissions && userPermissions.includes("account_delete") &&
                                    <Button color="secondary" className="icon-only" size="small" onClick={() => { deleteAccountData(account_id) }}>
                                        <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16 height-100p">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Account Information</Typography>

                        <Grid container spacing={1.5}>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'organization_name') }}
                                    onCancel={() => { onCancelEdit('organization_name') }}
                                    value={accountData?.organization_name || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Name" value={accountData?.organization_name || 'N/A'} errorMessages={errorMessages['organization_name']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Company Name" value={accountData?.organization_name || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'organization_name', 'Organization Name')}
                                    validationMessage={errorMessages['organization_name']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['organization_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_name']}</span>}
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'organization_website') }}
                                    onCancel={() => { onCancelEdit('organization_website') }}
                                    value={accountData?.organization_website || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Website" value={accountData?.organization_website} errorMessages={errorMessages['organization_website']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Company Website" element={
                                        accountData?.organization_website ? <a href={accountData?.organization_website} className="primary-link">{accountData?.organization_website}</a> : 'N/A'
                                    } />}
                                    onValidate={(value) => handleValidation(value, 'organization_website', 'Organization Website', 'isWebsite')}
                                    validationMessage={errorMessages['organization_website']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['organization_website'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_website']}</span>}
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'organization_email') }}
                                    onCancel={() => { onCancelEdit('organization_email') }}
                                    value={accountData?.organization_email || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Email" value={accountData?.organization_email || 'N/A'} errorMessages={errorMessages['organization_email']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Company Email" element={
                                        accountData?.organization_email ? <a href={"mailto:" + accountData?.organization_website} className="primary-link">{accountData?.organization_email}</a> : 'N/A'
                                    } />}
                                    onValidate={(value) => handleValidation(value, 'organization_email', 'Email', 'isEmail', false)}
                                    validationMessage={errorMessages['organization_email']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['organization_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_email']}</span>}
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'organization_phone1') }}
                                    onCancel={() => { onCancelEdit('organization_phone1') }}
                                    value={accountData?.organization_phone1 || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Phone 1" value={accountData?.organization_phone1} errorMessages={errorMessages['organization_phone1']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Company Phone 1" element={
                                        accountData?.organization_phone1 ? <a href={"tel:" + accountData?.organization_phone1 || 'N/A'} className="primary-link">{accountData?.organization_phone1}</a> : 'N/A'
                                    } />}
                                    onValidate={(value) => handleValidation(value, 'organization_phone1', 'Phone', 'phone', false)}
                                    validationMessage={errorMessages['organization_phone1']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['organization_phone1'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_phone1']}</span>}
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'organization_phone2') }}
                                    onCancel={() => { onCancelEdit('organization_phone2') }}
                                    value={accountData?.organization_phone2 || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Phone 2" value={accountData?.organization_phone2} errorMessages={errorMessages['organization_phone2']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Company Phone 2" element={
                                        accountData?.organization_phone2 ? <a href={"tel:" + accountData?.organization_phone2 || 'N/A'} className="primary-link">{accountData?.organization_phone2 || 'N/A'}</a> : 'N/A'
                                    } />}
                                    onValidate={(value) => handleValidation(value, 'organization_phone2', 'Phone', 'phone', false)}
                                    validationMessage={errorMessages['organization_phone2']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['organization_phone2'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_phone2']}</span>}
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <FormGroupStatic label="Created By" value={userData?.name || 'N/A'} />
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <FormGroupStatic label="Created Date" value={
                                    accountData?.created_at
                                        ? moment.utc(accountData?.created_at)
                                            .tz(preTimeZoneKey)
                                            .format(preferredDateFormat)
                                        : "N/A"} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>


                <Grid item xs={12}>
                    {account_id &&
                        <Suspense fallback={<div>Loading...</div>}>
                            <AccountDealList
                                account_id={account_id}
                                handleReloadParentFunc={handleReloadFunc}
                                userPermissions={userPermissions}
                            />
                        </Suspense>
                    }
                </Grid>

                <Grid item xs={12}>
                    {account_id &&
                        <Suspense fallback={<div>Loading...</div>}>
                            <DealContactList
                                account_id={account_id}
                                handleReloadParentFunc={handleReloadFunc}
                                userPermissions={userPermissions}
                            />
                        </Suspense>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default AccountDetails;