import React, { useEffect, useState } from "react";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { mdiDrag, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import DeleteConfirmDialog from "../../../components/common/DeleteConfirmDialog";
import CreateEditStageDialog from "../components/CreateEditStageDialog";
import CreateNewStageDialog from "../components/CreateNewStageDialog";
import { Button, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import Icon from "@mdi/react";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import DraggableTableRow from '../components/DraggableTableRow'
import TablePagination from "@mui/material/TablePagination";
import { useSelector } from "react-redux";
function DealStages({ DealData, handleReloadFunc, perPage, currentPage, totalCount, handlePageChange, handleRowsPerPageChange }) {

    const [dealStages, setDealStages] = useState(DealData || []);
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const [dealPosition, setDealPosition] = useState();
    const isUsedValue = "deal";
    const userPermissions = useSelector((state) => state.user.permission);
    useEffect(() => {
        setDealStages(DealData || []);
    }, [DealData]);

    const handleDragEnd = async (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const updatedLeadStages = arrayMove(dealStages, oldIndex, newIndex);

            setDealStages(updatedLeadStages);

            const finalList = updatedLeadStages.map(stage => stage.name);
            const payload = {
                finalList,
                is_position_change: true,
                is_used: "deal"
            };

            try {
                const response = await axiosInstance.post('/api/stage/update', payload);
                if (response.status === 200) {
                    showSnackbar(response.data.message, 'success');
                } else {
                    showSnackbar('Unexpected response status', 'warning');
                }
            } catch (error) {
                showSnackbar('Failed to update stages', 'error');
            }
        }
    };

    const [createNewStageDialogOpen, setCreateNewStageDialogOpen] = React.useState(false);
    const openCreateNewStageDialog = () => {
        setCreateNewStageDialogOpen(true);
    }
    const closeCreateNewStageDialog = () => {
        setCreateNewStageDialogOpen(false);
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPositionData = async () => {
        try {
            const leadPositionResponse = await axiosInstance.get('api/stage/create');
            if (leadPositionResponse.status === 200) {
                setDealPosition(leadPositionResponse.data.data.latestDealPosition);
            }
            else {
                showSnackbar('Unexpected response status', 'warning');
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 300) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        }
    };

    useEffect(() => {
        getPositionData();
    }, [getPositionData]);

    const items = React.useMemo(() => dealStages?.map((stage) => stage.id), [dealStages]);


    return (
        <>
            <div className="card-header">
                <h6 className="title">Deal Stages</h6>
                {userPermissions && userPermissions.includes("stage_create") &&
                    <Button color="secondary" size="small" className="action" onClick={openCreateNewStageDialog}>
                        <span>
                            <Icon path={mdiPlus} color={'currentColor'} size={1} />
                            <span>Add</span>
                        </span>
                    </Button>
                }
            </div>

            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={10}></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell align="center">Active</TableCell>
                            <TableCell width={10}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dealStages && dealStages.length > 0 && (
                            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                {dealStages.map((stage, i) => {
                                    return <DraggableTableRow key={stage.id + ''} row={stage} handleReloadFunc={handleReloadFunc} />;
                                })}
                            </SortableContext>
                        )}
                    </TableBody>
                </Table>
            </DndContext>

            {
                dealStages && dealStages.length > 0 &&
                <TablePagination
                    className="mt-16"
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }

            {createNewStageDialogOpen && (
                <CreateNewStageDialog
                    open={createNewStageDialogOpen}
                    onClose={closeCreateNewStageDialog}
                    handleReloadFunc={handleReloadFunc}
                    position={dealPosition}
                    isUsed={isUsedValue}
                />
            )}

        </>
    );
}

export default DealStages;