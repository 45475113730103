import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Autocomplete, Button, Dialog, Grid, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import MyCustomNumberFormat from "../../../components/MyCustomNumberFormat";
import PercentageFormatCustom from "../../../components/MyCustomPercentageFormat";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { displayDate } from "../../../utils/Utils";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

function CloneDealDialog({ open, onClose, handleReloadFunc, sources, productList, selectedProductIds, dealData }) {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();
    const { preferredDateFormat, currencyCode, currencySymbol, currencyStringValue } = useSelector(state => state?.user);

    const productOptions = productList.map(product => ({
        key: product.id,
        value: product.name
    }));

    const lengthValidation = (fieldLength) => {
        var valid = true;
        if (fieldLength) {
            var str = fieldLength.replace(/\s/g, '')
            if (str.length < 2) {
                valid = false;
            }
        }

        return valid ? {
            isValid: true,
        } : {
            isValid: false,
            errorMessage: 'Must be at least 2 characters, no space allow',
        }
    }

    const validationSchema = yup.object().shape({
        first_name: yup.string().required('Enter first name').trim('First name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        last_name: yup.string().required('Enter last name').trim('Last name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        organization_name: yup.string().required('Enter company name').trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        organization_email: yup.string().email('Please enter valid email address').required('Enter company email').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        deal_name: yup.string().trim('Deal name cannot include space').strict(true).required('Enter deal name').max(55, 'Must be at most 55 characters'),
        deal_amount: yup.string().required('Enter deal amount'),
        deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
            .test('validator-custom-length', function (value) {
                const validation = lengthValidation(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            }),
        deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")
    })


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            title: values.deal_name,
            organization_name: values.organization_name,
            organization_email: values.organization_email,
            lead_source: values.lead_source,
            deal_amount: values.deal_amount,
            deal_description: values.deal_description,
            deal_probability: values.deal_probability,
            expected_closing_date: values.expected_closing_date ? moment(values.expected_closing_date).format("YYYY-MM-DD") : '',
            product_id: values.product_id,
            latitude: '',
            longitude: '',
        }
        showLoader()
        await axiosInstance.post("/api/deal/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
                navigate("/deals");
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }


    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Clone Deal</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        first_name: dealData?.ContactData?.first_name || '',
                        last_name: dealData?.ContactData?.last_name || '',
                        deal_name: dealData?.deal_name || '',
                        organization_name: dealData?.AccountData?.organization_name || '',
                        organization_email: dealData?.AccountData?.organization_email || '',
                        lead_source: dealData?.deal_source || '',
                        deal_amount: dealData?.deal_amount || '',
                        deal_description: dealData?.deal_description || '',
                        deal_probability: dealData?.deal_probability || '',
                        expected_closing_date: dealData?.expected_closing_date ? moment(dealData.expected_closing_date) : moment(),
                        product_id: selectedProductIds || [],
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Deal Name"
                                    variant="filled"
                                    name="deal_name"
                                    value={values?.deal_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.deal_name && touched.deal_name}
                                    helperText={(errors.deal_name && touched.deal_name) && errors.deal_name}
                                />

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Company Name"
                                            variant="filled"
                                            name="organization_name"
                                            value={values?.organization_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.organization_name && touched.organization_name}
                                            helperText={(errors.organization_name && touched.organization_name) && errors.organization_name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Company Email"
                                            variant="filled"
                                            name="organization_email"
                                            value={values?.organization_email || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.organization_email && touched.organization_email}
                                            helperText={(errors.organization_email && touched.organization_email) && errors.organization_email}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Deal Source"
                                            value={values.lead_source}
                                            name="lead_source"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.lead_source && touched.lead_source}
                                            helperText={(errors.lead_source && touched.lead_source) && errors.lead_source}

                                        >
                                            {
                                                sources && sources.length > 0 ?
                                                    sources.map((source, index) => (
                                                        <MenuItem key={index} value={source.title}>
                                                            {source.title}
                                                        </MenuItem>
                                                    )) :
                                                    <MenuItem value={-1}></MenuItem>

                                            }

                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Contact First Name"
                                            variant="filled"
                                            name="first_name"
                                            value={values?.first_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.first_name && touched.first_name}
                                            helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Contact Last Name"
                                            variant="filled"
                                            name="last_name"
                                            value={values?.last_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.last_name && touched.last_name}
                                            helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label="Expected Closing Date"
                                                format={displayDate(preferredDateFormat, 'half')}
                                                views={['year', 'month', 'day']}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'filled',
                                                        name: "expected_closing_date",
                                                        type: 'text',
                                                        error: errors.expected_closing_date && touched.expected_closing_date,
                                                        helperText: (errors.expected_closing_date && touched.expected_closing_date) && errors.expected_closing_date
                                                    }
                                                }}
                                                value={values?.expected_closing_date || null}
                                                onChange={(value) => setFieldValue("expected_closing_date", value, true)}
                                                onBlur={handleBlur}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                <Autocomplete
                                    multiple
                                    id="multi_selects"
                                    options={productOptions || []}
                                    getOptionLabel={(option) => option.value}
                                    value={productOptions.filter(option => values.product_id.includes(option.key))} // Show selected products
                                    onChange={(event, newValue) => {
                                        const selectedProductIds = newValue.map(option => option.key); // Extract product IDs
                                        setFieldValue('product_id', selectedProductIds); // Update Formik state
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Select Product"
                                            placeholder="Select product"
                                            autoFocus
                                            inputProps={{
                                                ...params.inputProps,
                                                onFocus: props.focusCallback,
                                            }}
                                        />
                                    )}
                                    error={errors.product_id && touched.product_id}
                                    helperText={(errors.product_id && touched.product_id) && errors.product_id}
                                />

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="filled"
                                            label='Deal Amount'
                                            id="deal_amount"
                                            name="deal_amount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter deal amount"
                                            value={values?.deal_amount || ''}
                                            InputProps={{
                                                inputComponent: MyCustomNumberFormat, //here last is input/text to display on page
                                                inputProps: { 'currencySymbol': currencySymbol, 'currencyCode': currencyCode, 'currencyStringValue': currencyStringValue, 'displayType': 'input' },//displayType=input/text
                                                endAdornment: <span className="text-adornment">{currencyCode}</span>
                                            }}
                                            error={errors.deal_amount && touched.deal_amount}
                                            helperText={(errors.deal_amount && touched.deal_amount) && errors.deal_amount}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Probability"
                                            variant="filled"
                                            name="deal_probability"
                                            value={values.deal_probability}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                                inputComponent: PercentageFormatCustom,
                                                endAdornment: <span className="text-adornment">%</span>
                                            }}
                                            error={errors.deal_probability && touched.deal_probability}
                                            helperText={(errors.deal_probability && touched.deal_probability) && errors.deal_probability}
                                        />
                                    </Grid>
                                </Grid>

                                <TextField
                                    label="Description"
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    name="deal_description"
                                    value={values?.deal_description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.deal_description && touched.deal_description}
                                    helperText={(errors.deal_description && touched.deal_description) && errors.deal_description}
                                />

                                <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                    <Button type="submit">Clone</Button>
                                    <Button color="secondary" onClick={onClose}>Cancel</Button>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CloneDealDialog;