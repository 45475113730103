import { useEffect } from 'react';
import axiosInstance from '../config/axiosConfig';
import { useSnackbar } from '../components/context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const useAxios = () => {
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        const responseInterceptor = axiosInstance.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response) {
                    switch (error.response.status) {
                        // case 300:
                        //     showSnackbar(error.response.data.message, 'error');
                        //     break;
                        // case 400:
                        //     showSnackbar('Bad Request - ' + error.response.data.message, 'error');
                        //     break;
                        case 401:
                            showSnackbar('Unauthorized - ' + error?.response?.data?.message, 'error');
                            break;
                        case 403:
                            showSnackbar('Forbidden - ' + error?.response?.data?.message, 'error');
                            break;
                        case 404:
                            showSnackbar('Not Found - ' + error?.response?.data?.message, 'error');
                            break;
                        case 405:
                            showSnackbar('Method Not Allowed - ' + error?.response?.data?.message, 'error');
                            break;
                        case 500:
                            showSnackbar('Internal Server Error', 'error');
                            const token = cookies.get('auth_token');
                            if (!token || token === 'undefined') {
                                navigate('/sign-in');
                            } else {
                                navigate('/dashboard');
                            }
                            break;
                        default:
                            //showSnackbar('An unexpected error occurred', 'error');
                            break;
                    }
                } else if (error.request) {
                    //showSnackbar('No response from server', 'error');
                } else {
                    //showSnackbar('Error setting up request', 'error');
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [navigate, showSnackbar]);

    return axiosInstance;
};

export default useAxios;