import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Checkbox, FormControlLabel, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AvatarInitials from "../../../components/common/AvatarInitials";
import EasyEdit, { Types } from "react-easy-edit";
import FormGroupStatic from "../../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../../components/common/EasyEditButtons";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { useDispatch } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig, validateField } from "../../../utils/Utils";
import EasyEditCustomTextbox from "../../../components/common/EasyEditCustomTextbox";
import { permissions } from "../../../redux/slices/userSlice";

function UserDetails(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const roleId = id
    const dispatch = useDispatch();
    const { showLoader, hideLoader } = useLoader();
    const axiosInstance = useAxios();
    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const { showSnackbar } = useSnackbar();

    const [roleDetail, setRoleDetail] = useState("");
    const [UserDataPermissions, setUserDataPermissions] = useState([]);
    const [permissionList, setPermissionsList] = useState([]);
    const [fullPermission, setFullPermission] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [reloadPage, setReloadPage] = useState(false);
    const [isEditableData, setIsEditableData] = useState(true);
    const [isCheckAllBoxCheck, setIsCheckAllBoxCheck] = useState(false);


    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [reloadPage, roleId]);


    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/role/edit/' + roleId).then((response) => {
            if (response.status === 200) {
                setRoleDetail(response?.data?.data?.record);
                setUserDataPermissions(response?.data?.data?.recordPermissions);
                setPermissionsList(response?.data?.data?.permissionArray);
                setFullPermission(response?.data?.data?.allPermission)
                setIsEditableData(response?.data?.data?.record?.is_editable ? true : false)
                setIsSelectAll(response?.data?.data?.allPermission?.length == response?.data?.data?.recordPermissions?.length ? true : false)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }

                    if (error.response.status === 300) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/settings/roles");
                    }
                }
            });
    }

    const onCancelEdit = (fieldName) => {
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const handleSaveData = async (value, name, type = null) => {
        const data = {
            id: roleId,
            permissions: UserDataPermissions,
            [name]: value,
        };
        showLoader()
        await axiosInstance.post("api/role/update", data).then((response) => {
            if (response.status === 200) {
                hideLoader()
                setReloadPage(reloadPage ? false : true)
                showSnackbar(response.data.message, "success");
                setAllowEditInline(true)
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader()
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
    }



    const handleCheckbox = async (e) => {
        e.persist();

        const isChecked = e.target.checked;
        var permissionData = UserDataPermissions;
        var perType = "";

        if (isChecked) {
            UserDataPermissions.push(parseInt(e.target.value));
            setUserDataPermissions(permissionData);
            perType = "add";
        } else {
            permissionData = permissionData.filter(
                (x) => x !== parseInt(e.target.value)
            );
            setUserDataPermissions(permissionData);
            perType = "remove";
        }

        const data = {
            id: roleId,
            name: roleDetail?.name,
            permissions: permissionData,
            permissionId: e.target.value,
            permissionType: perType,
        };
        showLoader()
        await axiosInstance.post("/api/role/update", data).then((response) => {
            if (response.status === 200) {
                hideLoader()
                setUserDataPermissions(response.data.data.permissions);
                if (response.data.data.permissionArray) {
                    dispatch(permissions(response.data.data.permissionArray));
                }
                setIsSelectAll(response?.data?.data?.permissions?.length == response?.data?.data?.permissionArray?.length ? true : false)
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400 || error.response.status === 300) {
                        showSnackbar(error.response.data.message, "warning");
                        handleReloadFunc()
                    }
                }
            });
    };


    const selectAll = async (e) => {
        setIsSelectAll(e.target.checked ? true : false);
        setIsCheckAllBoxCheck(e.target.checked ? true : false);
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        var perType = "";
                        if (isCheckAllBoxCheck) {
                            setUserDataPermissions([]);
                            perType = "remove";
                        } else {
                            setUserDataPermissions(fullPermission.map((li) => li.id));
                            perType = "add";
                        }

                        const data = {
                            id: roleId,
                            name: roleDetail?.name,
                            permissionType: perType,
                            permissions: [],
                            checkAll: e.target.checked ? 'yes' : 'no',
                        };

                        showLoader()
                        await axiosInstance.post("/api/role/update", data).then((response) => {
                            if (response.status === 200) {
                                hideLoader()
                                setUserDataPermissions(response.data.data.permissions);
                                if (response.data.data.permissionArray) {
                                    dispatch(permissions(response.data.data.permissionArray));
                                }
                            }
                        })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400 || error.response.status === 300) {
                                        showSnackbar(error.response.data.message, "warning");
                                        handleReloadFunc()
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    },
                    confirmMessage: 'Confirm Update',
                    message: 'Are you sure you want to update all permissions for the role?',
                    buttomName: 'Update'
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    };

    return (
        <>
            <Helmet>
                <title>User Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item xs={'auto'} className="flex-shrink-1">
                                <Grid container alignItems={'flex-start'} spacing={1.5}>
                                    <Grid item xs="auto">
                                        <AvatarInitials name={roleDetail?.name || ''} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{roleDetail?.name || ''}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Role Setting</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={6} lg={4}>
                                {
                                    isEditableData ?
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'name') }}
                                            onCancel={() => { onCancelEdit('name') }}
                                            value={roleDetail?.name || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Role Name" value={roleDetail?.name} errorMessages={errorMessages['name']} maxLength={50} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Role Name" value={roleDetail?.name || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'name', 'Role Name', '', true)}
                                            validationMessage={errorMessages['name']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        :
                                        roleDetail?.name || 'N/A'
                                }
                                {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Stack direction="row" justifyContent={'space-between'} alignItems={"center"} className="mb-8">
                            <Typography variant="title-medium-large-semibold" component={'h4'}>Permission</Typography>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Select All"
                                className="m-0"
                                checked={isSelectAll}
                                value={isSelectAll ? false : true}
                                onClick={(e) => selectAll(e)}
                            />
                        </Stack>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center">Create</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                        <TableCell align="center">List</TableCell>
                                        <TableCell align="center">View</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.entries(permissionList).map(([key, value]) => {
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell>{capitalize(key)}</TableCell>
                                                    {value.map(function (keys) {
                                                        const isChecked = UserDataPermissions.includes(keys.id);
                                                        return (
                                                            <TableCell key={keys.id} align="center">
                                                                <Checkbox
                                                                    className='grid-checkbox'
                                                                    key={keys.id}
                                                                    name="permissions[]"
                                                                    checked={isChecked}
                                                                    onChange={handleCheckbox}
                                                                    value={keys.id}
                                                                />
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default UserDetails;