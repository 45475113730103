import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "../components/SettingsTab";
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import Icon from "@mdi/react";
import { mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import DeleteConfirmDialog from "../../../components/common/DeleteConfirmDialog";
import { useNavigate } from "react-router-dom";
import CreateNewSourceDialog from "../components/CreateNewSourceDialog";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../../utils/Utils";

function Sources(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [sources, setSources] = useState();
    const [reloadPage, setReloadPage] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
            );
            hideLoader();
        } fetchData();
    }, [currentPage, perPage, reloadPage]);



    const getData = async (
        currentPage,
        perPage,
        sortBy,
    ) => {
        const params = {
            sort_field: sortBy,
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/source?page=${currentPage + 1}`, { params }).then((response) => {
                if (response.status === 200) {
                    setSources(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/source/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/settings/sources");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    const handleCheckboxChange = async (sourceId, currentStatus) => {
        const newStatus = currentStatus === 1 ? 0 : 1;
        try {
            const response = await axiosInstance.put("api/source/mode", { id: sourceId, status: newStatus });
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
                handleReloadFunc();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 300) {
                    showSnackbar(error.response.data.message, "warning");
                }
            } else {
                showSnackbar("An unexpected error occurred.", "error");
            }
        }
    };


    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(false);
    const openDeleteConfirmDialog = () => {
        setDeleteConfirmDialogOpen(true);
    }
    const closeDeleteConfirmDialog = () => {
        setDeleteConfirmDialogOpen(false);
    }


    const [createNewSourceDialogOpen, setCreateNewSourceDialogOpen] = React.useState(false);
    const openCreateNewSourceDialog = () => {
        setCreateNewSourceDialogOpen(true);
    }
    const closeCreateNewSourceDialog = () => {
        setCreateNewSourceDialogOpen(false);
    }

    const handlePageChange = (event, newPage) => { setCurrentPage(newPage) };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    return (
        <>
            <Helmet>
                <title>Sources | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />

            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Sources</h6>
                    <Button color="secondary" size="small" className="action" onClick={openCreateNewSourceDialog}>
                        <span>
                            <Icon path={mdiPlus} color={'currentColor'} size={1} />
                            <span>Add</span>
                        </span>
                    </Button>
                </div>

                <TableContainer className="mb-16">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell align="center">Active</TableCell>
                                <TableCell width={10}></TableCell>
                            </TableRow>
                        </TableHead>
                        {sources && sources.length > 0 && (
                            <TableBody>
                                {sources.map((source, index) => (
                                    <TableRow key={index + ''}>
                                        <TableCell>{source.title}</TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                className="grid-checkbox"
                                                checked={source.status === 1}
                                                onChange={() => handleCheckboxChange(source.id, source.status)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Delete">
                                                <span>
                                                    <button type="button" className="icon-button mr-n8" onClick={() => { deleteData(source?.id) }}>
                                                        <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                    </button>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                {sources && sources.length > 0 &&
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                }
            </div>

            <DeleteConfirmDialog open={deleteConfirmDialogOpen} onClose={closeDeleteConfirmDialog} message={'Are you sure you want to delete this source? This action cannot be undone.'} deleteButtonText={'Delete Source'} />
            <CreateNewSourceDialog open={createNewSourceDialogOpen} onClose={closeCreateNewSourceDialog} handleReloadFunc={handleReloadFunc} />
        </>
    );
}

export default Sources;