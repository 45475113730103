import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Checkbox, FormControlLabel, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import AvatarInitials from "../../../components/common/AvatarInitials";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomSelect from "../../../components/common/EasyEditCustomSelect";
import FormGroupStatic from "../../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../../components/common/EasyEditButtons";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../../utils/Utils";

var selectedRolePer = [];
var userRoleId = "";

function UserDetails(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const userId = id
    const { showLoader, hideLoader } = useLoader();
    const axiosInstance = useAxios();
    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const { showSnackbar } = useSnackbar();

    const [dataList, setDataList] = useState("");
    const [UserDataPermissions, setUserDataPermissions] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    //const [monthSalesTarget, setMonthSalesTarget] = useState("");
    const [roleList, setRoleList] = useState("");
    const [selectRole, setSelectRole] = useState('');
    const [permissionList, setPermissionsList] = useState([]);
    const [totalPermission, setTotalPermission] = useState();
    const [fullPermission, setFullPermission] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [reloadPage, userId]);


    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/user/edit/' + userId).then((response) => {
            if (response.status === 200) {
                setDataList(response.data.data.record);
                setUserDataPermissions(response.data.data.selectedPermissions);
                setSelectedRole(response.data.data.selectedRole[0]);
                // if (response.data.data.record.monthly_target) {
                //     setMonthSalesTarget(
                //         response.data.data.record.monthly_target[0].monthly_target
                //     );
                // }
                setRoleList(response.data.data.roles);
                setPermissionsList(response.data.data.permissionArray);
                setTotalPermission(response.data.data.totalPermission)
                setFullPermission(response.data.data.permissions)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }

                    if (error.response.status === 300) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/settings/users");
                    }
                }
            });
    }

    const onCancelEdit = (fieldName) => {
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }


    const handleSaveData = async (value, name, type = null) => {
        setSelectedRole(value);
        userRoleId = value;
        showLoader()
        if (value && !isNaN(value)) {
            selectedRolePer = []
            showLoader();
            await axiosInstance.get(`api/user/getPermission?id=` + userRoleId).then((response) => {
                if (response.status === 200) {
                    setUserDataPermissions(response.data.data.recordPermissions);
                    selectedRolePer = response.data.data.recordPermissions;
                    const data = {
                        id: userId,
                        role: userRoleId,
                        permissions: selectedRolePer,
                    };

                    axiosInstance.post("/api/user/update", data).then((response) => {
                        window.location.reload(true);
                    }).then((json) => { })
                        .catch((error) => {
                            if (error.response) {
                                hideLoader()
                                if (error.response.status === 422 || error.response.data.validation_errors) {
                                    setAllowEditInline(true)
                                    const errors = error.response.data.validation_errors;
                                    Object.keys(errors).forEach(key => {
                                        setBackendErrorMessages({ [key]: errors[key] });
                                    });
                                } else {
                                    showSnackbar(error.response.data.message, 'error')
                                }
                            }
                            setTimeout(() => {
                                window.location.reload(true);
                            }, 1000);
                        });
                }
            })
                .then((json) => { })
                .catch((error) => {
                    if (error.response) {
                        hideLoader()
                        if (error.response.status === 422 || error.response.data.validation_errors) {
                            setAllowEditInline(true)
                            const errors = error.response.data.validation_errors;
                            Object.keys(errors).forEach(key => {
                                setBackendErrorMessages({ [key]: errors[key] });
                            });
                        } else {
                            showSnackbar(error.response.data.message, 'error')
                        }
                    }
                });
        } else {
            handleReloadFunc()
            showSnackbar('Please select valid role', 'error')
            setAllowEditInline(true)
            hideLoader()
        }
    }
    useEffect(() => {
        const selectedRoleName = roleList && roleList.find(role => role.id === dataList?.selected_roles?.[0]?.role_id);
        if (selectedRoleName) {
            setSelectRole(selectedRoleName?.name)
        }
    }, [roleList])

    const handleCheckbox = async (e) => {
        e.persist();
        const isChecked = e.target.checked;
        var permissionData = UserDataPermissions;
        var perId = e.target.value;
        var perActionType = "";

        if (isChecked) {
            UserDataPermissions.push(parseInt(e.target.value));
            setUserDataPermissions(permissionData);
            perActionType = "add";
        } else {
            permissionData = permissionData.filter(
                (x) => x !== parseInt(e.target.value)
            );
            setUserDataPermissions(permissionData);
            perActionType = "remove";
        }

        const data = {
            id: userId,
            role: selectedRole,
            permissions: permissionData,
            updatePerId: perId,
            perActType: perActionType,
        };

        showLoader()
        await axiosInstance.post("/api/user/update", data).then((response) => {
            if (response.status === 200) {
                hideLoader()
                setUserDataPermissions(response.data.data.permissions);
                showSnackbar(response.data.message, "success");
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const selectAll = async (e) => {
        const data = {
            id: userId,
            role: selectedRole,
            permissions: fullPermission,
            checkAll: e.target.checked ? 'yes' : 'no',
        };
        setIsSelectAll(e.target.checked ? true : false);
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.post("/api/user/update", data).then((response) => {
                            if (response.status === 200) {
                                hideLoader()
                                showSnackbar(response.data.message, "success");
                                setUserDataPermissions(response.data.data.selectedPermissionArray);
                                //window.location.reload();
                            }
                        })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    },
                    confirmMessage: 'Confirm Update',
                    message: 'Are you sure you want to update all permissions for the user?',
                    buttomName: 'Update'
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    };

    return (
        <>
            <Helmet>
                <title>User Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item xs={'auto'} className="flex-shrink-1">
                                <Grid container alignItems={'flex-start'} spacing={1.5}>
                                    <Grid item xs="auto">
                                        <AvatarInitials name={dataList?.name || ''} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{capitalize(dataList?.name || 'N/A')}</Typography>
                                        <ul className='inline-details'>
                                            <li><Tooltip title="Email"><span>{dataList?.email || ''}</span></Tooltip></li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                <span className="status-badge size-lg">{dataList?.selected_role || ''}</span>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Access Setting</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={6} lg={4}>
                                <EasyEdit
                                    type={Types.SELECT}
                                    value={selectRole || ''}
                                    onSave={(value) => { handleSaveData(value, 'role') }}
                                    onCancel={() => { onCancelEdit() }}
                                    allowEdit={allowEditInline}
                                    editComponent={
                                        <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Role" fieldValue={dataList?.selected_roles?.[0]?.role_id || ''}
                                            items={
                                                roleList && roleList.length > 0 ?
                                                    roleList.map((role) => (
                                                        { value: role.id, option: role.name }
                                                    ))
                                                    :
                                                    { value: -1, option: 'Select' }
                                            }
                                        />
                                    }
                                    displayComponent={<FormGroupStatic easyEdit label="Role" value={selectRole || ''} />}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['role'] && <span className="easy-edit-validation-error">{backendErrorMessages['role']}</span>}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Stack direction="row" justifyContent={'space-between'} alignItems={"center"} className="mb-8">
                            <Typography variant="title-medium-large-semibold" component={'h4'}>Permission</Typography>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Select All"
                                className="m-0"
                                checked={UserDataPermissions?.length === totalPermission ? true : false}
                                value={isSelectAll ? false : true}
                                onClick={(e) => selectAll(e)}
                            />


                        </Stack>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center">Create</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                        <TableCell align="center">List</TableCell>
                                        <TableCell align="center">View</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.entries(permissionList).map(([key, value], index) => {
                                            return (
                                                <TableRow key={index + ''}>
                                                    <TableCell>{capitalize(key)}</TableCell>
                                                    {value.map(function (keys) {
                                                        const isChecked = (selectedRolePer && selectedRolePer.includes(keys.id)) ||
                                                            UserDataPermissions.includes(keys.id);
                                                        return (
                                                            <TableCell key={keys.id + ''} align="center">
                                                                <Checkbox
                                                                    className='grid-checkbox'
                                                                    key={keys.id}
                                                                    name="permissions[]"
                                                                    checked={isChecked}
                                                                    onChange={handleCheckbox}
                                                                    value={keys.id}
                                                                />
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default UserDetails;