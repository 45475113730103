import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Dialog, Tooltip } from "@mui/material";
import { Circle, GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useMemo, useState } from "react";

function ViewLocationDialog({ onClose, open, locationDetail }) {
    const googles = window.google;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const [mapRef, setMapRef] = useState();
    const [isOpens, setIsOpens] = useState(false);
    const [isDisplayMap, setIsDisplayMap] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const [isfillData, setIsfillData] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(8);
    const [map, setMap] = React.useState(null);

    useEffect(() => {
        if (locationDetail.latitude && locationDetail.longitude) {
            setIsDisplayMap(true)
        }

    }, [locationDetail])

    const markers = [{
        address: locationDetail?.visitedTime,
        lat: parseFloat(locationDetail?.latitude),
        lng: parseFloat(locationDetail?.longitude),
    }];

    const center = useMemo(() => ({ lat: locationDetail?.latitude, lng: locationDetail?.longitude }), []);

    const onMapLoad = (map) => {
        setMapRef(map);
        const bounds = new googles.maps.LatLngBounds();
        markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        map.fitBounds(bounds);
        setZoomLevel(zoomLevel);
    };

    const handleMarkerClick = (id, lat, lng, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, address });
        setIsOpens(true);
        setZoomLevel(50);
    };

    setTimeout(() => {
        setIsfillData(true);
    }, 2000);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);


    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>View Location</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                {/* <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.849839226924!2d72.5323230147499!3d23.029285046721053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84c92801abe1%3A0x1e87b88447826d9e!2sITC%20Narmada%2C%20a%20Luxury%20Collection%20Hotel%2C%20Ahmedabad!5e0!3m2!1sen!2sin!4v1723554577815!5m2!1sen!2sin" allowFullScreen="" className="view-location-map" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                <div style={{ height: "78.8vh", width: "100%" }}>
                    {!isLoaded ? (
                        <h1>Loading...</h1>
                    ) : !isfillData ? (
                        <center>
                            <h2>Please wait we are finding your user location</h2>
                        </center>
                    ) : !isDisplayMap ? (
                        <center>
                            <h2>Oops...! We can not find latitude and longitude</h2>
                        </center>
                    ) : (
                        <GoogleMap
                            mapContainerClassName="map-container"
                            onLoad={onMapLoad}
                            center={center}
                            zoom={zoomLevel}
                            onClick={() => setIsOpens(false)}
                            onUnmount={onUnmount}
                        >
                            {markers.map(({ address, lat, lng }, ind) => (
                                <Marker
                                    key={ind}
                                    position={{ lat, lng }}
                                    onClick={() => {
                                        handleMarkerClick(ind, lat, lng, address);
                                    }}
                                >
                                    <Circle
                                        defaultCenter={{
                                            lat: parseFloat(lat),
                                            lng: parseFloat(lng),
                                        }}
                                        radius={3000}
                                        options={{ strokeColor: "#ff0000" }}
                                    />
                                    {isOpens && infoWindowData?.id === ind && (
                                        <InfoWindow
                                            onCloseClick={() => {
                                                setIsOpens(false);
                                            }}
                                        >
                                            <h3>{infoWindowData.address}</h3>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            ))}
                        </GoogleMap>
                    )}
                </div>
            </Dialog>
        </>
    );
}

export default ViewLocationDialog;