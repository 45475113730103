import React, { useEffect } from 'react';
import AppRoutes from './routes';
import { ThemeProvider, Typography } from '@mui/material';
import { SnackbarProvider } from './components/context/SnackbarContext';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppleAppStore, GooglePlayStore } from './assets/images';
import Loader from './components/Loader';
import { LoaderProvider } from './components/context/LoaderContext';
import { solvCrmTheme } from './themes/solvCrmTheme';
import themeProvider from './themeProvider';
import { xlFinancialTheme } from './themes/xlFinancialTheme';
import { Helmet } from 'react-helmet';

export const ThemeContext = React.createContext(null);

const App = () => {
    const [currentTheme, setCurrentTheme] = React.useState(null);
    const [theme, setTheme] = React.useState(themeProvider(solvCrmTheme));

    //Forcefully redirect if subdomain not passed in url
    const currentUrl = window.location.href;
    useEffect(() => {
        const subdomain = 'app';
        const urlObj = new URL(currentUrl);
        const hostParts = urlObj.hostname.split('.');
        // Check if the URL already has a subdomain
        if (urlObj.hostname === 'localhost') {
            // Add subdomain
            const newHost = `${subdomain}.${urlObj.hostname}`;
            urlObj.hostname = newHost;

            // Redirect to the new URL with the subdomain
            window.location.replace(urlObj.href);
        } else if (!hostParts.includes('localhost')) {
            // Check if the URL already has a subdomain
            if (hostParts.length === 2) {
                // Add subdomain
                const newHost = `${subdomain}.${urlObj.hostname}`;
                urlObj.hostname = newHost;
                // Redirect to the new URL with the subdomain
                window.location.replace(urlObj.href);
            }
        }
    }, [currentUrl]);

    const displayTitle = {
        'localhost:3001': 'xl-financial',
        'solvcrm.asaya.in': 'SolvCRM',
        'xlcrm.xl.financial': 'xl-financial',
        'default': 'SolvCRM',
    };


    React.useEffect(() => {
        if (localStorage.getItem('sidebar-collapsed') === 'true') {
            document.body.classList.add('sidebar-collapsed');
        }

        const themes = {
            'localhost:3001': 'xl-financial',
            // 'app.multi-tenant-crm.com:3004': 'xl-financial',
            'solvcrm.asaya.in': 'solvcrm',
            'xlcrm.xl.financial': 'xl-financial',
            'default': 'solvcrm',
        };

        const currentThemeString = themes[window.location.hostname] || themes['default'];
        document.documentElement.classList.add(`theme-${currentThemeString}`);
        switch (currentThemeString) {
            case 'solvcrm':
                setTheme(themeProvider(solvCrmTheme));
                setCurrentTheme(solvCrmTheme);
                break;
            case 'xl-financial':
                setTheme(themeProvider(xlFinancialTheme));
                setCurrentTheme(xlFinancialTheme);
                break;
            default:
                setTheme(themeProvider(solvCrmTheme));
                setCurrentTheme(solvCrmTheme);
                break;
        }
    }, []);

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    return (
        <>
            <Helmet>
                <link rel="icon" href={currentTheme?.favicon} />
                <meta name="theme-color" content={currentTheme?.themeColor} />
            </Helmet>

            <Provider store={store}>
                <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
                    <ThemeContext.Provider value={{ currentTheme: currentTheme }}>
                        <ThemeProvider theme={theme}>
                            <main className="main-wrapper">
                                <SnackbarProvider>
                                    <LoaderProvider>
                                        <AppRoutes headerTitle={displayTitle[window.location.hostname] || displayTitle['default']} />
                                        <Loader />
                                    </LoaderProvider>
                                </SnackbarProvider>
                            </main>
                            <div className="mobile-not-supported">
                                <img src={currentTheme?.logoMark} alt={currentTheme?.appName} className='logo' />
                                <div className="content">
                                    <img src={currentTheme?.mobileIllustration} alt="Mobile Detected Icon" className='illustration' />
                                    <Typography variant='headline-medium-bold' component={'h1'}>Oops! Mobile Detected</Typography>
                                    {getMobileOperatingSystem() !== 'unknown' && <Typography variant='body-large-medium' component={'p'}>It looks like you're on a mobile device. For the best experience, please switch to a tablet, laptop, or desktop. Alternatively, you can download our mobile app from the {getMobileOperatingSystem() === 'Android' && <span>Google Play Store</span>}{getMobileOperatingSystem() === 'iOS' && <span>App Store</span>}. We can’t wait to see you there!</Typography>}
                                    {getMobileOperatingSystem() === 'unknown' && <Typography variant='body-large-medium' component={'p'}>It looks like you're on a mobile device. For the best experience, please switch to a tablet, laptop, or desktop. We can’t wait to see you there!</Typography>}
                                    {getMobileOperatingSystem() === 'iOS' && <a href="-" title="Download from Apple App Store" className='badge'>
                                        <img src={AppleAppStore} alt="Apple App Store Badge" />
                                    </a>}
                                    {getMobileOperatingSystem() === 'Android' && <a href="-" title="Download from Google Play Store" className='badge'>
                                        <img src={GooglePlayStore} alt="Google Play Store Badge" />
                                    </a>}
                                </div>
                            </div>
                        </ThemeProvider>
                    </ThemeContext.Provider>
                </PersistGate>

            </Provider >
        </>

    )
}

export default App;