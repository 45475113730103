import { mdiAccountOutline, mdiBriefcaseAccountOutline, mdiCalendarCheckOutline, mdiDomain, mdiEmailOutline, mdiHistory, mdiPencilOutline, mdiPhoneOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Grid, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import AvatarInitials from "../../../components/common/AvatarInitials";
import { Link, useNavigate } from "react-router-dom";
import CreateNewContactDialog from "../../contact/components/CreateNewcontactDialog";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import Swal from "sweetalert2";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayCurrencySymbol, displayDate } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import EmptyState from "../../../components/common/EmptyState";

function DealQuoteList({ dealId }) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencySymbol, currencyCode, currencyStringValue } = useSelector((state) => state?.user);

    //default data for list api
    const [sortBy, setSortBy] = useState("created_at");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const [reloadPage, setReloadPage] = useState(false);

    const [quotes, setQuotes] = useState([]);

    useEffect(() => {
        if (dealId) {
            async function fetchData() {
                showLoader();
                await getData();
            }
            fetchData();
        }
    }, [dealId, reloadPage]);

    const getData = async () => {
        const params = {
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
            page: currentPage,
            deal_id: dealId
        };

        await axiosInstance.get(`api/quote?page=${currentPage}`, { params }).then(response => {
            if (response.status === 200) {
                setQuotes(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/deal");
                    }
                }
            });
    };


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    //=======Start::Write a code for delete deal quote=======//
    async function DeleteQuote(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/quote/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete deal quote=======//


    return (
        <>
            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Quotes</h6>
                </div>
                {quotes && quotes.length > 0 && (
                    <ul className="primary-list">
                        {quotes.map((quote, index) =>
                            <li key={index + ''}>
                                <Link to={`/quote-detail/${quote?.id}`} className="card">
                                    <Typography variant='title-medium-bold' component={'h6'}>{capitalize(quote?.subject || 'N/A')}</Typography>
                                    <ul className='inline-details'>
                                        <li><span className="bold">{quote?.total ? displayCurrencySymbol(quote?.total, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</span></li>
                                        <li>
                                            <Tooltip title="Quote Number">
                                                <span>
                                                    <Icon path={mdiDomain} color="currentColor" size={1} />{quote?.quote_number || 'N/A'}  {quote?.quote_stage ? ' - ' + quote?.quote_stage : ''}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Contact Person">
                                                <span>
                                                    <Icon path={mdiBriefcaseAccountOutline} color="currentColor" size={1} />{quote?.selected_contact_name || 'N/A'}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Created Date">
                                                <span>
                                                    <Icon path={mdiCalendarCheckOutline} color="currentColor" size={1} />{quote?.created_at
                                                        ? moment.utc(quote?.created_at)
                                                            .tz(preTimeZoneKey)
                                                            .format(preferredDateFormat)
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                    <ul className='inline-details other-info ml-0'>
                                        <li><span className='title'>Valid Until:</span>{quote?.valid_until
                                            ? displayDate(moment.utc(quote?.valid_until).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                            : "N/A"}</li>
                                        <li>
                                            <Tooltip title="Added by">
                                                <span>
                                                    <Icon path={mdiAccountOutline} color="currentColor" size={1} /> {quote?.added_by?.name || 'N/A'}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Last Updated">
                                                <span>
                                                    <Icon path={mdiHistory} color="currentColor" size={1} />{quote?.updated_at
                                                        ? moment.utc(quote?.updated_at)
                                                            .tz(preTimeZoneKey)
                                                            .format(preferredDateFormat)
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </Link>
                                <Stack direction={'row'} className='actions'>
                                    <Tooltip title="Edit">
                                        <span>
                                            <Link to={`/quote-detail/${quote?.id}`} className='icon-button'>
                                                <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                            </Link>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <button type="button" className='icon-button' onClick={(e) => {
                                            DeleteQuote(quote?.id);
                                        }}>
                                            <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                        </button>
                                    </Tooltip>
                                </Stack>
                            </li>
                        )
                        }
                    </ul>
                )}

                {(!quotes || quotes.length === 0) && !loading &&
                    <EmptyState type={'quote'} hideActionBtn size="small" />
                }

                {
                    quotes?.length > 0 &&
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                }
            </div>
        </>
    );
}

export default DealQuoteList;