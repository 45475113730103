import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LogoMark } from "../../assets/images";
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Icon from '@mdi/react';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import { capitalize } from "../../utils/Utils";

function TenantInvitation(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const [displayInvitationName, setDisplayInvitationName] = useState('');

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const pathname = window.location.pathname;
    const getArray = pathname.split('/');
    const id = getArray[2];
    const email = getArray[3];

    useEffect(() => {
        checkUserVerification();
    }, [id]);

    async function checkUserVerification() {
        showLoader('Please wait while we check your account details.This will only take a moment.')
        await axiosInstance.get("/api/invitation-email-verify/" + id + "/" + email).then(response => {
            if (response.status === 200) {
                hideLoader()
                if (response.data.data.type === 'login') {
                    showSnackbar(response.data.message, 'success')
                    navigate('/sign-in');
                } else {
                    setDisplayInvitationName(response.data.data.name);
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                showSnackbar(error.response.data.message, 'error')
                navigate('/sign-in');
            }
        });
    }



    const validationSchema = yup.object().shape({
        name: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your organization name'),
        //email: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your organization email').email('Please enter a valid email address'),
        password: yup.string().required('Password cannot be less than 8 characters').min(8).max(20).matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\+\-\/])(?=.{8,})/,
            "Must Contain one Uppercase, one Lowercase, one Number and one Special Case Character"
        ),
        confirm_password: yup.string().required('Enter your confirm password').oneOf([yup.ref('password'), null], "Password doesn't match")
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            name: values.name,
            email: values.email,
            password: values.password,
            confirm_password: values.confirm_password,
            terms: values.terms,
            invitationId: id
        }
        showLoader('Please wait, we are processing your signup. This will only take a moment.')
        await axiosInstance.post("/api/invitation-register", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                navigate('/sign-in');
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                    navigate('/sign-up');
                }
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Create Your Account | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={0}>
                <Grid item xs={12} xl={6} sx={{ display: { xl: 'block', xs: 'none' } }}>
                    <div className="login-banner"></div>
                </Grid>
                <Grid item xs={12} xl={6} className="login-form-outer">
                    <div className="login-form-wrapper">
                        <img src={LogoMark} alt="SolvCRM" className="login-logo" />
                        <Typography variant="headline-medium-bold" className="login-title" component={'h2'}>Create an account from {displayInvitationName}</Typography>
                        <Typography variant="body-large-semibold" className="login-subtitle" component={'p'}>Sign up to unlock powerful CRM features and take your sales management to the next level.</Typography>

                        <Formik
                            initialValues={{
                                name: '',
                                email: email,
                                password: '',
                                confirm_password: '',
                                error_list: [],
                                userTimeZone: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            label="Organization Name"
                                            variant="filled"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.name && touched.name}
                                            helperText={(errors.name && touched.name) && errors.name}
                                        />


                                        <TextField
                                            label="Email"
                                            variant="filled"
                                            name="email"
                                            disabled
                                            value={values.email}
                                            error={errors.email && touched.email}
                                            helperText={(errors.email && touched.email) && errors.email}
                                        />

                                        <TextField
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            variant="filled"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword
                                                                ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                                : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.password && touched.password}
                                            helperText={(errors.password && touched.password) && errors.password}
                                        />

                                        <TextField
                                            label="Confirm Password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            variant="filled"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showConfirmPassword
                                                                ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                                : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            name="confirm_password"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.confirm_password && touched.confirm_password}
                                            helperText={(errors.confirm_password && touched.confirm_password) && errors.confirm_password}
                                        />

                                        <Button type="submit" className="btn-block">Sign Up</Button>
                                    </form>
                                );
                            }}
                        </Formik>

                        <div className="login-signup">
                            <Typography variant="label-large">Already have an account?</Typography>
                            <Link to="/sign-in" className="primary-link"><Typography variant="label-large-bold">Sign In</Typography></Link>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default TenantInvitation;