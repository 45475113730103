import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import DeleteConfirmDialog from "../../../components/common/DeleteConfirmDialog";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomTextbox from "../../../components/common/EasyEditCustomTextbox";
import FormGroupStatic from "../../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../../components/common/EasyEditButtons";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig, validateField } from "../../../utils/Utils";
import AvatarInitials from "../../../components/common/AvatarInitials";
import EasyEditCustomAmountTextbox from "../../../components/common/EasyEditCustomAmountTextbox";
import FormGroupStaticAmount from "../../../components/common/FormGroupStaticAmount";

function ProductDetails() {
    const { id } = useParams();
    const productId = id
    const axiosInstance = useAxios();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);
    const [prodcutData, setProdcutData] = useState();
    const { showSnackbar } = useSnackbar();
    const { preferredDateFormat, preTimeZoneKey, currencyCode, currencySymbol, currencyStringValue } = useSelector((state) => state?.user);
    const userPermissions = useSelector((state) => state.user.permission);
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        async function fetchData() {
            showLoader();
            getData();
        }
        fetchData();
    }, [reloadPage]);


    const getData = async () => {
        await axiosInstance.get('api/product/edit/' + productId).then((response) => {
            if (response.status === 200) {
                setProdcutData(response?.data?.data?.record);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/settings/products");
                    }
                }
            });
    }

    async function deleteContactData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/product/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/settings/products");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    const handleSaveData = async (value, name, type = null) => {
        const data = {
            id: productId,
            name: prodcutData?.name || '',
            [name]: value,
        };
        function getValueByKey(recordData, key) {
            return recordData[key];
        }
        const newValue = getValueByKey(prodcutData, name);
        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/product/update", data).then(response => {
                if (response.status === 200) {
                    setReloadPage(reloadPage ? false : true)
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(allowEditInline ? false : true)
                    setErrorMessages({})
                    setBackendErrorMessages({})
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key][0] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        } else {
            setReloadPage(reloadPage ? false : true)
            setAllowEditInline(allowEditInline ? false : true)
        }
    }

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(true);
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const handleClick = (e) => {
        setAllowEditInline(false)
    }
    const [allowEditInline, setAllowEditInline] = React.useState(true);


    return (
        <>
            <Helmet>
                <title> Product Details | SolvCRM </title>
            </Helmet>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item xs={'auto'} className="flex-shrink-1">
                                <Grid container alignItems={'flex-start'} spacing={1.5}>
                                    <Grid item xs="auto">
                                        {prodcutData?.images && prodcutData.images.length > 0 ? (
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}storage/${prodcutData.images[0].file_url}`}
                                                alt="Profile"
                                                className="user-avatar"
                                            />
                                        ) : (
                                            <AvatarInitials name={prodcutData?.name} />
                                        )}
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{capitalize(prodcutData?.name || 'N/A')}</Typography>
                                        <ul className='inline-details'>
                                            <li><span className="title">Last Updated:</span>
                                                {prodcutData?.updated_at
                                                    ? moment
                                                        .utc(prodcutData.updated_at)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                {userPermissions && userPermissions.includes("product_delete") &&
                                    <Tooltip title="Delete">
                                        <Button color="secondary" className="icon-only" size={'small'} onClick={() => { deleteContactData(productId) }} sx={{ marginRight: 0.5 }}>
                                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                        </Button>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16 height-100p">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Product Information</Typography>

                        <Grid container spacing={1.5}>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'name') }}
                                    onCancel={() => { onCancelEdit('name') }}
                                    value={prodcutData?.name || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Product name" value={prodcutData?.name} errorMessages={errorMessages['name']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Product name" value={prodcutData?.name || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'name', 'Product name', '', true)}
                                    validationMessage={errorMessages['name']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'price') }}
                                    onCancel={() => { onCancelEdit('price') }}
                                    value={prodcutData?.price || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomAmountTextbox setAllowEditInline={setAllowEditInline} name='price' label="Price" value={prodcutData?.price} errorMessages={errorMessages['price']} maxLength={50} currencySymbol={currencySymbol} currencyCode={currencyCode} currencyStringValue={currencyStringValue} />}
                                    displayComponent={<FormGroupStaticAmount easyEdit label="Price" value={prodcutData?.price || 'N/A'} currencyCode={currencyCode} currencySymbol={currencySymbol} currencyStringValue={currencyStringValue} />}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />

                                {backendErrorMessages['price'] && <span className="easy-edit-validation-error">{backendErrorMessages['price']}</span>}
                            </Grid>

                            <Grid item xl={6} xs={12}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'description') }}
                                    onCancel={() => { onCancelEdit('description') }}
                                    value={prodcutData?.description || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description" value={prodcutData?.description} errorMessages={errorMessages['description']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Description" value={prodcutData?.description || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'description', 'Description', '', true)}
                                    validationMessage={errorMessages['description']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['description'] && <span className="easy-edit-validation-error">{backendErrorMessages['description']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'product_code') }}
                                    onCancel={() => { onCancelEdit('product_code') }}
                                    value={prodcutData?.product_code || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Product code" value={prodcutData?.product_code} errorMessages={errorMessages['product_code']} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Product code" value={prodcutData?.product_code || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'product_code', 'Product code', '', true)}
                                    validationMessage={errorMessages['product_code']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'tax') }}
                                    onCancel={() => { onCancelEdit('tax') }}
                                    value={prodcutData?.tax || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomAmountTextbox setAllowEditInline={setAllowEditInline} name='tax' label="Tax" value={prodcutData?.tax} errorMessages={errorMessages['price']} maxLength={50} currencySymbol={currencySymbol} currencyCode={currencyCode} currencyStringValue={currencyStringValue} />}
                                    displayComponent={<FormGroupStaticAmount easyEdit label="Tax" value={prodcutData?.tax || 'N/A'} currencyCode={currencyCode} currencySymbol={currencySymbol} currencyStringValue={currencyStringValue} />}
                                    onValidate={(value) => handleValidation(value, 'tax', 'Tax', '', false, 0)}
                                    validationMessage={errorMessages['tax']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <FormGroupStatic easyEdit label="Created by" value={prodcutData?.Username?.name + ' (' + (prodcutData?.created_at
                                    ? moment
                                        .utc(prodcutData.created_at)
                                        .tz(preTimeZoneKey)
                                        .format(preferredDateFormat)
                                    : "N/A") + ')'} />
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <FormGroupStatic easyEdit label="Modified by" value={prodcutData?.Username?.name + ' (' + (prodcutData?.updated_at
                                    ? moment
                                        .utc(prodcutData.updated_at)
                                        .tz(preTimeZoneKey)
                                        .format(preferredDateFormat)
                                    : "N/A") + ')'} />
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            </Grid>

        </>
    );
}

export default ProductDetails;