import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordIllustration } from "../../assets/images/index";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import { capitalize } from "../../utils/Utils";

function ForgotPassword(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const [displayMessage, setDisplayMessage] = useState(true);

    const validationSchema = yup.object().shape({
        email: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter your email address').email('Please enter a valid email address'),
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            email: values.email,
        }
        showLoader('Please wait, we are sending an email. This will only take a moment.');
        await axiosInstance.post("/api/forgot-password", data).then(response => {
            if (response.status === 200) {
                onSubmitProps.setSubmitting(false)
                onSubmitProps.resetForm({ values: '' })
                setDisplayMessage(false)
                hideLoader('');
            }
            showSnackbar(response.data.message, 'success')
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader('');
                setDisplayMessage(false)
                onSubmitProps.setSubmitting(false)
                onSubmitProps.resetForm({ values: '' })
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                }
            }
        });

    }

    return (
        <>
            <Helmet>
                <title>Forgot Password | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={0}>
                <Grid item xs={12} xl={6} sx={{ display: { xl: 'block', xs: 'none' } }}>
                    <div className="login-banner"></div>
                </Grid>
                <Grid item xs={12} xl={6} className="login-form-outer">
                    <div className="login-form-wrapper">

                        {displayMessage ?
                            <>
                                <Button variant="text" size="small" className="edge-start back-btn" onClick={e => navigate('/')}>
                                    <span>
                                        <Icon path={mdiArrowLeft} color={'currentColor'} size={1} />
                                        <span>Back</span>
                                    </span>
                                </Button>
                                <Typography variant="headline-medium-bold" className="login-title" component={'h2'}>Forgot Your Password?</Typography>
                                <Typography variant="body-large-semibold" className="login-subtitle" component={'p'}>Enter your registered email address, and we’ll send you a link to reset your password.</Typography>
                                <Formik
                                    initialValues={{
                                        email: '',
                                        error_list: [],
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    enableReinitialize
                                >
                                    {props => {
                                        const {
                                            touched,
                                            errors,
                                            values,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                        } = props;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <TextField
                                                    label="Email"
                                                    variant="filled"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.email && touched.email}
                                                    helperText={(errors.email && touched.email) && errors.email}
                                                />
                                                <Button type="submit" className="btn-block">Send Reset Link</Button>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </>
                            :

                            <div className="reset-password-info">
                                <img src={ForgotPasswordIllustration} alt="Illustration" />
                                <Typography variant="headline-medium-bold" className="login-title" component={'h2'}>Check Your Email</Typography>
                                <Typography variant="body-large-semibold" className="login-subtitle" component={'p'}>We've sent password reset instructions to the email you provided. Follow the steps to regain access to your account.</Typography>
                                <Button onClick={e => navigate('/')}>Go to Sign In</Button>
                            </div>

                        }
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default ForgotPassword;