import { mdiClose, mdiFileDocument, mdiFilePdfBox, mdiImage, mdiMusicBox, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Dialog, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { useSelector } from "react-redux";

function AttachmentViewerDialog({ onClose, open, attachments, attachmentType, HandleDeleteAttachment, isDelete = true, isDownload = false }) {

    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const iconMapping = {
        image: mdiImage,
        audio: mdiMusicBox,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
        // Add more mappings as needed
    };

    const DeleteAttachment = (id, parentId) => {
        HandleDeleteAttachment(id, parentId);
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Attachments</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                {attachments && attachments.length > 0 &&
                    <Grid container component={'ul'} spacing={1.5} className="attachments">
                        {attachments.map((attachment, index) => {
                            const fileUrlParts = attachment?.file_url?.split(attachmentType);
                            const fileName = fileUrlParts?.length === 2 ? fileUrlParts[1] : '';
                            const fileExtension = attachment && attachment?.file_url?.split('.').pop().toLowerCase();

                            let fileType = '';
                            if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                fileType = 'image';
                            } else if (['mp3', 'wav', 'ogg'].includes(fileExtension)) {
                                fileType = 'audio';
                            } else if (['pdf'].includes(fileExtension)) {
                                fileType = 'pdf';
                            } else {
                                fileType = 'file';
                            }
                            const IconComponent = iconMapping[fileType];

                            return (
                                <Grid item xs={12} component={'li'} key={index}>
                                    <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_API_URL + 'storage/' + attachment.file_url}`}>
                                        <div className="icon-wrapper">
                                            {IconComponent && <Icon path={IconComponent} color={'currentColor'} size={1} />}
                                        </div>
                                        <div className="info">
                                            <Typography variant="body-large" component={'p'}>{fileName}</Typography>
                                            {attachment?.created_at && <Typography variant="body-medium">{moment.tz(attachment?.created_at, preTimeZoneKey).format(preferredDateFormat)}</Typography>}
                                        </div>
                                    </a>
                                    {
                                        isDelete &&
                                        <button className="icon-button size-small" onClick={e => { e.stopPropagation(); DeleteAttachment(attachment?.id, attachmentType === '/notes/' ? attachment?.note_id : attachment?.activity_id) }}>
                                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                        </button>
                                    }

                                </Grid>
                            );
                        })}
                    </Grid>}
            </Dialog>
        </>
    );
}

export default AttachmentViewerDialog;